import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  panel: {
    '@media (max-width: 636px)': {
      width: '-webkit-fill-available',
      minWidth: 50,
      maxWidth: '100%',
    },
    background: `linear-gradient(120deg, ${theme.palette.creditCard.light}, ${theme.palette.creditCard.dark} 50%, ${theme.palette.creditCard.light} 90%)`,
    maxWidth: '100%',
    minWidth: 312,
  },
  highlighted: {
    boxShadow: `0px 0px 2px 2px ${theme.palette.highlightablePanel.light}`,
  },
}));

const HighlightablePanel = ({highlighted, onPressed, children}) => {
  const classes = useStyles(mainTheme);
  return (
    <>
      <Card onClick={() => onPressed()} classes={{root: clsx(classes.panel, {[classes.highlighted]: highlighted})}} elevation={2}>
        <CardContent>{children}</CardContent>
      </Card>
    </>
  );
};

HighlightablePanel.defaultProps = {
  highlighted: false,
  onPressed: () => {},
};

HighlightablePanel.propTypes = {};

export default HighlightablePanel;
