import React, {useState} from 'react';
import _ from 'lodash';
import ManageCreditCardsDetailsStep from './ManageCreditCardsDetailsStep';
import ManageCreditCardsNoCreditCardStep from './ManageCreditCardsNoCreditCardStep';
import ManageCreditCardsAddingCreditCardStep from './ManageCreditCardsAddingCreditCardStep';
import {creditCardService} from '../../../services/creditCardService';
import {ErrorBar, InfoBar, ProcessingBar, SuccessBar} from '../../../commons/snackbars/snackbars';
import GenericLoadingStep from '../../Dashboard/GenericLoadingStep';
import PaperWithHeading from '../../../commons/containers/PaperWithHeading/PaperWithHeading';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {EMPTY, from, of} from 'rxjs';
import {useStripe} from '@stripe/react-stripe-js';

export const ManageCreditCardStep = {
  LOADING: 'LOADING',
  NO_CREDIT_CARD: 'NO_CREDIT_CARD',
  CREDIT_CARD_DETAILS: 'CREDIT_CARD_DETAILS',
  ADDING_CREDIT_CARD: 'ADDING_CREDIT_CARD',
  REMOVING_CREDIT_CARD: 'REMOVING_CREDIT_CARD',
};

// See https://stripe.com/docs/api/setup_intents/object#setup_intent_object-status
export const CreditCardSetupStatus = Object.freeze({
  SUCCEEDED: 'succeeded',
  REQUIRES_ACTION: 'requires_action',
  PROCESSING: 'processing',
  CANCELED: 'canceled',
});

const ManageCreditCards = (props) => {
  const [step, setStep] = useState(ManageCreditCardStep.LOADING);
  const [creditCard, setCreditCard] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [processing, setProcessing] = useState(false);

  const stripe = useStripe();

  const onCreditCardDetailsRetrieved = (creditCards) => {
    if (_.isEmpty(creditCards)) {
      setStep(ManageCreditCardStep.NO_CREDIT_CARD);
      return;
    }
    setCreditCard(creditCards[0]);
    setStep(ManageCreditCardStep.CREDIT_CARD_DETAILS);
  };

  const onAddCreditCardRequest = () => {
    setStep(ManageCreditCardStep.ADDING_CREDIT_CARD);
    setErrorMessage(null);
    setInfoMessage(null);
    setSuccessMessage(null);
    setProcessing(false);
  };

  const onNewCreditCardDetails = (paymentMethodId) => {
    setErrorMessage(null);
    setInfoMessage(null);
    setSuccessMessage(null);
    setProcessing(true);
    creditCardService
      .addCreditCard(paymentMethodId)
      .pipe(
        switchMap((response) => {
          // Show 3D Secure popup if required
          if (response.status === CreditCardSetupStatus.REQUIRES_ACTION) {
            return from(stripe.confirmCardSetup(response.client_secret, {payment_method: response.payment_method_id}));
          } else {
            return of(response);
          }
        }),
        tap((response) => {
          if (response.error) throw new Error(response.error.message);

          setStep(ManageCreditCardStep.LOADING);
          setProcessing(false);
          setErrorMessage(null);
          setInfoMessage(null);
          setSuccessMessage('Your card was saved successfully!');
        }),
        catchError((error) => {
          setStep(ManageCreditCardStep.LOADING);
          setProcessing(false);
          setErrorMessage(error.message);
          setInfoMessage(null);
          setSuccessMessage(null);
          return EMPTY;
        })
      )
      .subscribe();
  };

  const onRemoveCreditCard = (paymentMethodId) => {
    setErrorMessage(null);
    setInfoMessage(null);
    setSuccessMessage(null);
    setProcessing(true);
    setStep(ManageCreditCardStep.REMOVING_CREDIT_CARD);
    creditCardService.removeCreditCard(paymentMethodId).subscribe(
      (response) => {
        setProcessing(false);
        setErrorMessage(null);
        setSuccessMessage(null);
        setInfoMessage('Your card was removed successfully!');
        setStep(ManageCreditCardStep.LOADING);
      },
      (error) => {
        setProcessing(false);
        setSuccessMessage(null);
        setInfoMessage('Unable to remove credit card');
        setErrorMessage(null);
        setStep(ManageCreditCardStep.CREDIT_CARD_DETAILS);
      }
    );
  };

  let content;
  switch (step) {
    case ManageCreditCardStep.NO_CREDIT_CARD:
      content = <ManageCreditCardsNoCreditCardStep emitAddCreditCardRequest={onAddCreditCardRequest} />;
      break;
    case ManageCreditCardStep.ADDING_CREDIT_CARD:
      content = <ManageCreditCardsAddingCreditCardStep emitNewCreditCardDetails={onNewCreditCardDetails} />;
      break;
    case ManageCreditCardStep.CREDIT_CARD_DETAILS:
    case ManageCreditCardStep.REMOVING_CREDIT_CARD:
      content = <ManageCreditCardsDetailsStep creditCard={creditCard} emitRemoveCreditCard={onRemoveCreditCard} step={step} />;
      break;
    case ManageCreditCardStep.LOADING:
    default:
      content = <GenericLoadingStep observableFunction={creditCardService.getCreditCards} successCallback={onCreditCardDetailsRetrieved} />;
      break;
  }

  return (
    <>
      <PaperWithHeading maxWidth="xs">{content}</PaperWithHeading>
      <ProcessingBar open={processing}>Processing</ProcessingBar>
      <InfoBar open={!_.isEmpty(infoMessage)}>{infoMessage}</InfoBar>
      <SuccessBar open={!_.isEmpty(successMessage)}>{successMessage}</SuccessBar>
      <ErrorBar open={!_.isEmpty(errorMessage)}>{errorMessage}</ErrorBar>
    </>
  );
};

ManageCreditCards.propTypes = {};

export default ManageCreditCards;
