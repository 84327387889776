import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `linear-gradient(30deg, ${mainTheme.palette.background.gradient.from} 0%, ${mainTheme.palette.background.gradient.to} 100%)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(2),
  },
}));

const OpenLoopPaper = ({children}) => {
  const classes = useStyles(mainTheme);

  return <Box className={classes.background}>{children}</Box>;
};

export default OpenLoopPaper;
