import React, {createRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import {CheckboxWithLabel, TextField} from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import {ErrorBar, ProcessingBar} from '../../commons/snackbars/snackbars';
import Box from '@material-ui/core/Box';
import SmartPhoneField from '../../commons/form/SmartPhoneField/SmartPhoneField';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';
import useSignUp, {SignUpEvent} from './hook/useSignUp';

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const SignUpEnterPersonalDetails = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useSignUp();
  const formikRef = createRef();
  useEffect(() => {
    if (!state.submitting) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.submitting, formikRef]);
  return (
    <>
      <FullPageContainer headingLabel="Sign Up">
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstName: '',
            lastName: '',
            mobileNumber: '',
            optInToDiscountsAndNews: false,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .required('Required')
              .matches(/[a-zA-Z]/, 'Invalid first name'),
            lastName: Yup.string()
              .required('Required')
              .matches(/[a-zA-Z]/, 'Invalid last name'),
            mobileNumber: Yup.string()
              .required('Required')
              .matches(/^(\+\d{1,3}[- ]?)?\d{9}/, 'Invalid mobile number'),
          })}
          onSubmit={(values) => {
            const personalDetails = {
              ...values,
            };

            addEvent({type: SignUpEvent.PERSONAL_DETAILS_SUBMITTED, payload: {personalDetails}});
          }}
        >
          {(formikProps) => (
            <>
              <Form>
                <Box classes={{root: classes.formRow}}>
                  <Field name="firstName" component={TextField} type="text"  variant="outlined" label="First Name" fullWidth autoFocus />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name="lastName" component={TextField} type="text"  variant="outlined" label="Last Name" fullWidth />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name="mobileNumber" component={SmartPhoneField} type="text"  variant="outlined" label="Mobile Number" fullWidth />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="optInToDiscountsAndNews"
                    Label={{
                      label: <Typography>I would like to receive emails for discounts & news from OpenLoop.</Typography>,
                    }}
                  />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                    Register
                  </Button>
                </Box>
                {/*<Box classes={{root: classes.formRow}}>*/}
                {/*  <Button*/}
                {/*    onClick={() => {*/}
                {/*      addEvent({type: RegisterEvent.BACK_BUTTON_PRESSED});*/}
                {/*    }}*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    size="large"*/}
                {/*    fullWidth*/}
                {/*    disabled={formikProps.isSubmitting}*/}
                {/*  >*/}
                {/*    Back*/}
                {/*  </Button>*/}
                {/*</Box>*/}
                <ProcessingBar open={state.submitting} fullPage />
                <ErrorBar open={state.errorMessage} fullPage>
                  {state.errorMessage}
                </ErrorBar>
              </Form>
            </>
          )}
        </Formik>
      </FullPageContainer>
    </>
  );
};

export default SignUpEnterPersonalDetails;
