import React, {createContext, useCallback, useMemo, useState} from 'react';
import {useLoadingContainerWithErrorPanel} from '../../hooks/useLoadingContainerWithErrorPanel';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import chargeTagService from '../../services/chargeTagService';
import _ from 'lodash';
import ChargeTagTabs from './ChargeTagTabs';
import ChargeTagDetails from './ChargeTagDetails';
import {InfoBar, SuccessBar} from '../../commons/snackbars/snackbars';

// flow state
export const ChangeTagsFlowState = {
  EMPTY: 'empty',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted',
};

// context
export const ChargeTagsContext = createContext();

const ChargeTags = () => {
  const [changeTagsFlowState, setChangeTagsFlowState] = useState(ChangeTagsFlowState.EMPTY);
  const [chargeTag, setChargeTag] = useState(null);

  const observable = useMemo(() => chargeTagService.getChargeTags(), []);
  const onSuccessCallback = useCallback((chargeTags) => (_.isEmpty(chargeTags) ? setChargeTag(null) : setChargeTag(chargeTags[0])), []);
  const onErrorCallback = useCallback((error) => setChargeTag(null), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  const content = _.isNull(chargeTag) ? (
    <ChargeTagTabs />
  ) : (
    <ChargeTagDetails
      chargeTag={chargeTag}
      deactivateSuccessHandler={() => setChangeTagsFlowState(ChangeTagsFlowState.DEACTIVATED)}
      reactivateSuccessHandler={() => setChangeTagsFlowState(ChangeTagsFlowState.ACTIVATED)}
      deleteSuccessHandler={() => setChangeTagsFlowState(ChangeTagsFlowState.DELETED)}
    />
  );

  return (
    <>
      <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
        <ChargeTagsContext.Provider value={setChangeTagsFlowState}>{content}</ChargeTagsContext.Provider>
      </LoadingContainerWithErrorPanel>
      <SuccessBar open={changeTagsFlowState === ChangeTagsFlowState.ACTIVATED}>Your charge tag has been activated successfully!</SuccessBar>
      <InfoBar open={changeTagsFlowState === ChangeTagsFlowState.DEACTIVATED}>Your charge tag has been deactivated!</InfoBar>
      <InfoBar open={changeTagsFlowState === ChangeTagsFlowState.DELETED}>Your charge tag has been deleted!</InfoBar>
    </>
  );
};
export default ChargeTags;
