import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import {Button, Typography} from '@material-ui/core';
import {Field, Form, Formik} from 'formik';
import chargeTagService from '../../../services/chargeTagService';
import * as Yup from 'yup';
import SmartTextField from '../../../commons/form/SmartTextField';
import LoadingModal from '../../../commons/layout/LoadingModal';
import InvalidChargeTagIdException from '../../../services/exceptions/InvalidChargeTagIdException';
import ChargeTagAlreadyActiveException from '../../../services/exceptions/ChargeTagAlreadyActiveException';
import ActiveChargeTagsLimitExceededException from '../../../services/exceptions/ActiveChargeTagsLimitExceededException';
import {WarningBar} from '../../../commons/snackbars/snackbars';
import PropTypes from 'prop-types';
import PaperWithHeading from '../../../commons/containers/PaperWithHeading/PaperWithHeading';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '350px',
    margin: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
  },
}));

const ActivateChargeTag = ({activateSuccessHandler}) => {
  const classes = useStyles(mainTheme);
  const [failedActivation, setFailedActivation] = useState(false);
  const [failedActivationErrorMessage, setFailedActivationErrorMessage] = useState('');

  return (
    <PaperWithHeading maxWidth="xs">
      <>
        <Typography>Please find the tag ID in text in the attached letter</Typography>
        <br />
        <Formik
          initialValues={{
            tagId: '',
          }}
          onSubmit={({tagId}, {setSubmitting}) => {
            setSubmitting(true);
            setFailedActivation(false);
            chargeTagService.activateChargeTag(tagId).subscribe(
              (result) => {
                setSubmitting(false);
                activateSuccessHandler();
              },
              (error) => {
                setSubmitting(false);
                setFailedActivation(true);

                if (error instanceof InvalidChargeTagIdException) {
                  setFailedActivationErrorMessage('Please enter a valid Charge Tag ID!');
                  return;
                }

                if (error instanceof ChargeTagAlreadyActiveException) {
                  setFailedActivationErrorMessage('Charge Tag has already been activated!');
                  return;
                }

                if (error instanceof ActiveChargeTagsLimitExceededException) {
                  setFailedActivationErrorMessage('You have reached the limit of registering two Activate Charge Tags on your account!');
                  return;
                }
              }
            );
          }}
          validationSchema={Yup.object({
            tagId: Yup.string().required('Required').min(8, 'Tag id must be at least 8 characters').matches('[0-9A-Fa-f]{8,}', 'Tag id must conform to ISO/IEC 14443-3'),
          })}
        >
          {(formikProps) => (
            <>
              <Form>
                <Field label="Tag ID" name="tagId" type="text" component={SmartTextField} fullWidth variant="outlined" />
                <Button
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}
                >
                  Activate
                </Button>
              </Form>
              <LoadingModal open={formikProps.isSubmitting} />
            </>
          )}
        </Formik>
        <WarningBar open={failedActivation && failedActivationErrorMessage}>{failedActivationErrorMessage}</WarningBar>
      </>
    </PaperWithHeading>
  );
};

ActivateChargeTag.propTypes = {
  activateSuccessHandler: PropTypes.func.isRequired,
};

export default ActivateChargeTag;
