import React from 'react';
import PropTypes from 'prop-types';
import EmptyListView from '../table/EmptyListView';
import {List} from '@material-ui/core';
import _ from 'lodash';

const ListWrapper = ({children}) => (_.isNil(children) ? <EmptyListView /> : <List disablePadding>{children}</List>);

ListWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ListWrapper;
