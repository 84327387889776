import Backdrop from '@material-ui/core/Backdrop';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Spinner from 'react-spinkit';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.secondary.light,
  },
}));

const LoadingModal = ({open = true}) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Spinner name="three-bounce" color="white" />
    </Backdrop>
  );
};

export default LoadingModal;
