import React from 'react';
import PropTypes from 'prop-types';
import PanelLoadingIndicator from '../PanelLoadingIndicator';
import ErrorContainer from '../ErrorContainer/ErrorContainer';

const LoadingContainerWithErrorPanel = ({loading, hasError, errorMessage, children}) => {
  if (loading) {
    return <PanelLoadingIndicator />;
  }

  if (hasError) {
    return <ErrorContainer message={errorMessage} />;
  }

  return <>{children}</>;
};

LoadingContainerWithErrorPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default LoadingContainerWithErrorPanel;
