import React from 'react';
import useSignUp, {SignUpFlowState} from './hook/useSignUp';
import {withSignUp} from './hook/withSignUp';
import SignUpEnterEmail from './SignUpEnterEmail';
import SignUpEnterSignUpCode from "./SignUpEnterSignUpCode";
import SignUpEnterPassword from './SignUpEnterPassword';
import SignUpEnterPersonalDetails from './SignUpEnterPersonalDetails';
import SignUpSuccessful from './SignUpSuccessful';

const SignUpFlow = () => {
  const {state} = useSignUp();
  switch (state.flowState) {
    case SignUpFlowState.ENTER_EMAIL:
      return <SignUpEnterEmail />;
    case SignUpFlowState.ENTER_SIGN_UP_CODE:
      return <SignUpEnterSignUpCode />;
    case SignUpFlowState.ENTER_PASSWORD:
      return <SignUpEnterPassword />
    case SignUpFlowState.ENTER_PERSONAL_DETAILS:
      return <SignUpEnterPersonalDetails />
    case SignUpFlowState.SIGN_UP_SUCCESSFUL:
      return <SignUpSuccessful />
    default:
      throw new Error('Invalid flow state.');
  }
};

export default withSignUp(SignUpFlow);
