// The stable createMuiTheme does not support ReactStrictMode yet and it's rolling out the support in the next version
// which is in unstable_createMuiStrictModeTheme.
// Once we upgraded @material-ui/core to the next version we can pull in the stable createMuiTheme.
// I have done regression testing on the theme and found that there is no issues using this "unstable" version
// It helps remove the noisy warnings from React linter and helps us more focused on the real issues.
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import {unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes} from '@material-ui/core';
import DegularBold from '../assets/fonts/Degular-Bold.ttf';
import DegularMedium from '../assets/fonts/Degular-Medium.ttf';
import DegularLight from '../assets/fonts/Degular-Light.ttf';

// breakpoints
const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const colors = {
  // white
  xxLightWhite: 'rgba(255, 255, 255, 0.1)',
  xLightWhite: 'rgba(255, 255, 255, 0.8)',
  lightWhite: 'rgba(255, 255, 255, 0.9)',
  white: 'rgba(255, 255, 255, 1)',

  // red
  red: 'rgba(244, 67, 54, 1)',

  // orange
  orange: 'rgba(255, 152, 0, 1)',
  darkOrange: 'rgba(255, 152, 0, 1)',

  // green
  green: 'rgba(76, 175, 80, 1)',

  // blue
  xxLightBlue: 'rgb(84,177,248, 0.1)',
  xLightBlue: 'rgb(84,177,248, 0.2)',
  lightBlue: 'rgba(0, 185, 240, 1)',
  blue: 'rgba(2, 181, 222, 1)',
  darkBlue: 'rgba(1, 87, 155, 1)',
  xDarkBlue: 'rgba(20, 0, 100, 1)',
  xxDarkBlue: 'rgb(12, 59, 85, 0.8)',
  xxxDarkBlue: 'rgba(5, 30, 52, 1)',

  // purple
  lightPurple: 'rgba(139, 89, 255, 1)',
  purple: 'rgb(129,5,196)',
  xPurple: 'rgba(140, 0, 240, 1)',

  // grey
  xlightGrey: 'rgb(222,222,222)',
  lightGrey: 'rgb(196,196,196)',
  grey: 'rgb(129,129,129,1)',
  darkGrey: 'rgb(89,89,89)',
  xDarkGrey: 'rgb(71,71,71)',
  matteGrey: 'rgba(125, 125, 125, 1)',
  solidLightGrey: 'rgb(189, 189, 189)',

  // black
  lightBlack: 'rgba(0, 0, 0, 0.8)',
  black: 'rgba(31, 31, 31, 1)',

  // soft colours
  softGrey: 'rgba(50, 50, 93, 0.1)',
  softBlueGrey: 'rgba(60, 60, 120, 0.2)',
  softBlack: 'rgba(0, 0, 0, 0.2)',
  softWhite: 'rgba(255, 255, 255, 0.5)',
  softBlue: 'rgba(236, 240, 250, 0.2)',

  panelGrey: 'rgba(233, 233, 233, 0.8)',
  transparent: 'rgba(0, 0, 0, 0)',
};

const degularBold = {
  fontFamily: 'Degular',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${DegularBold}) format('truetype')`,
};

const degularMedium = {
  fontFamily: 'Degular',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url(${DegularMedium}) format('truetype')`,
};

const degularLight = {
  fontFamily: 'Degular',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${DegularLight}) format('truetype')`,
};

// mainTheme
let mainTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  typography: {
    fontFamily: ['Degular'],
    button: {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    h1: {
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 600,
      lineHeight: 2,
    },
    h3: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    body1: {
      color: colors.black,
    },
    body2: {
      color: colors.black,
    },
  },
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 32,
          maxWidth: '100%',
          minWidth: 97,
        },
      },
    },
    MuiCard: {
      root: {
        width: '-webkit-fill-available',
        maxWidth: 312,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    InputElement: {
      root: {
        fontFamily: 'Degular',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 11px) scale(1)',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 14px',
      },
      root: {
        borderRadius: 0,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 50,
      },
      containedSizeLarge: {
        fontSize: '1rem',
      },
      fullWidth: {
        '@media (max-width: 600px)': {
          width: '100%',
        },
        width: 200,
      },
      label: {
        // This block forces the correct sentence case for all buttons
        display: 'inline-block',
        fontWeight: 600,
        textTransform: 'lowercase',
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      },
    },
    MuiPaper: {
      root: {
        color: colors.black,
      },
    },
    MuiSnackbar: {
      root: {
        left: 16,
        right: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [degularLight, degularMedium, degularBold],
      },
    },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
        },
      },
      regular: {
        '@media (min-width: 600px)': {
          minHeight: 42,
        },
      },
      root: {
        '& button': {
          color: colors.black,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: colors.xxLightBlue,
        },
        '&$hover:hover': {
          backgroundColor: colors.xlightGrey,
        },
      },
    },
    MuiTableCell: {
      root: {
        color: colors.grey,
        padding: '8px 8px 8px 16px',
        borderBottom: 'none',
        fontSize: '1rem',
      },
      head: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: colors.panelGrey,
        color: colors.xDarkBlue,
      },
      alignRight: {
        textAlign: 'right',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.white,
        '&:focus': {
          color: colors.white,
        },
        '&:hover': {
          fontWeight: 'bold',
          color: colors.white,
          '& $icon': {
            fontWeight: 'bold',
            color: colors.white,
            opacity: 1,
          },
        },
        '&$active': {
          color: colors.white,
          fontWeight: 'bold',
          '&:hover': {
            color: colors.white,
          },
        },
        // This is horrible but it's necessary to fix the damn bug in material table sort label
        // It's used to defeat this selector
        // .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon
        '&$active$root$active$root $icon': {
          color: colors.white,
          fontWeight: 'bold',
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid`,
        borderBottomColor: colors.lightGrey,
        marginBottom: 16,
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: colors.transparent,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '1rem',
      },
      textColorPrimary: {
        color: colors.grey,
        '&$selected': {
          color: colors.black,
        },
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
      },
    },
    MuiLink: {
      underlineNone: {
        textDecoration: 'none',
        fontWeight: 600,
      },
      root: {
        textDecoration: 'underline',
        color: colors.black,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        color: colors.black,
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottomColor: colors.black,
        },
      },
    },
    MuiTablePagination: {
      root: {
        color: colors.xDarkBlue,
      },
    },
    MuiSelect: {
      root: {
        color: colors.black,
      },
      icon: {
        color: colors.black,
      },
    },
    MuiTypography: {
      root: {
        color: colors.black,
      },
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key) => `@media (min-width:${values[key]}px)`,
  },
  spacing: 8,
  palette: {
    primary: {
      light: colors.black,
      main: colors.black,
      dark: colors.black,
      contrastText: colors.lightWhite,
    },
    secondary: {
      light: colors.xxDarkBlue,
      main: colors.xxxDarkBlue,
      contrastText: colors.white,
    },
    action: {
      main: colors.lightWhite,
      dark: colors.xxxDarkBlue,
      contrastText: colors.xxxDarkBlue,
    },
    hint: {
      main: colors.matteGrey,
      satisfied: colors.green,
    },
    divider: colors.lightGrey,
    text: {
      onBrightBackground: colors.black,
      onDarkBackground: colors.lightWhite,
      onDarkBackgroundBright: colors.lightWhite,
      onDarkBackgroundLightBright: colors.xLightWhite,
      onBrightBackgroundUserBalance: colors.lightBlue,
    },
    background: {
      chargeTagStatus: {
        active: colors.blue,
        deactivated: colors.red,
      },
      connectorStatus: {
        available: colors.blue,
        unavailable: colors.grey,
        charging: colors.purple,
      },
      locationStatus: {
        available: colors.blue,
        unavailable: colors.grey,
        charging: colors.purple,
      },
      sessionStatus: {
        active: colors.green,
        completed: colors.grey,
        other: colors.red,
      },
      gradient: {
        from: colors.lightPurple,
        to: colors.lightBlue,
      },
    },
    notification: {
      info: {
        backgroundColor: colors.blue,
      },
      success: {
        backgroundColor: colors.green,
      },
      warning: {
        backgroundColor: colors.orange,
      },
      error: {
        backgroundColor: colors.red,
      },
    },
    processingBar: {
      backgroundColor: colors.xPurple,
    },
    shadow: {
      inset: colors.softBlueGrey,
      soft: colors.softGrey,
      mid: colors.softBlack,
      highlight: colors.softWhite,
    },
    tab: {
      divider: colors.darkGrey,
    },
    drawer: {
      backgroundColor: colors.white,
      divider: colors.xxLightWhite,
      titleBar: colors.white,
    },
    drawerItem: {
      selectedBackgroundColor: 'rgba(0, 0, 0, 0.5)',
      selectedTextColor: colors.white,
    },
    table: {
      headingRow: colors.xxDarkBlue,
      oddRow: colors.panelGrey,
      search: colors.xDarkBlue,
    },
    chargeTagStatus: {
      active: colors.green,
      deactivated: colors.red,
    },
    balance: {
      low: colors.darkOrange,
      ok: colors.green,
      hint: colors.darkOrange,
    },
    payment: {
      panel: colors.softBlue,
      border: colors.softBlueGrey,
    },
    money: {
      credit: colors.green,
      debit: colors.orange,
      totalCost: colors.blue,
    },
    highlightablePanel: {
      light: colors.blue,
      lighter: colors.lightBlue,
    },
    creditCard: {
      light: colors.lightGrey,
      dark: colors.solidLightGrey,
      text: colors.xDarkGrey,
    },
  },
  leftDrawer: {
    width: 260,
  },
});

mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
