import React, {useCallback, useMemo, useState} from 'react';
import transactionService from '../../services/transactionService';
import {useLoadingContainerWithErrorPanel} from '../../hooks/useLoadingContainerWithErrorPanel';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import {ErrorBar} from '../../commons/snackbars/snackbars';
import Dashboard from '../Dashboard/Dashboard';
import {isMobile} from 'react-device-detect';
import TransactionDesktopView from './TransactionsDesktopView';
import TransactionsMobileView from './TransactionsMobileView';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);

  const observable = useMemo(() => transactionService.getTransactions(), []);
  const onSuccessCallback = useCallback((response) => setTransactions(response), []);
  const onErrorCallback = useCallback((error) => setTransactions([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  const content = isMobile ? <TransactionsMobileView transactions={transactions} /> : <TransactionDesktopView transactions={transactions} />;

  return (
    <Dashboard title="Transactions">
      <>
        <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>{content}</LoadingContainerWithErrorPanel>
        <ErrorBar open={loadingContainerWithErrorPanelState.hasError}>{loadingContainerWithErrorPanelState.errorMessage}</ErrorBar>
      </>
    </Dashboard>
  );
};

export default Transactions;
