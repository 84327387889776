// Firebase configuration
// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import appConfig from './utils/config/appConfig';
// See https://firebase.google.com/docs/web/setup#available-libraries

// Call this function to initialize firebase for the app
const initializeFirebaseForApp = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = appConfig.firebase;
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  return getAnalytics(app);
}

export default initializeFirebaseForApp