import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import {ThemeProvider} from '@material-ui/core/styles';
import mainTheme from './themes/mainTheme';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import _ from 'lodash';
import lodashExtras from './utils/lodash_extras/lodashExtras';
import initializeFirebaseForApp from './firebaseConfiguration';
import {HelmetProvider} from 'react-helmet-async';

// create extra functions for lodash
_.mixin(lodashExtras);

// Initialize Firebase (analytics)
initializeFirebaseForApp()

const root = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  root
);

// FIXME: This is used to help debug context however it causes the site to slow down dramatically so for now it's disabled
// Once a solution is found, please re-enable it
// Attach root
/*
debugContextDevtool(
  root,
  {
    disable: process.env.NODE_ENV === "production"
  },
);
 */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
