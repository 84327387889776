import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import mainTheme from '../../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import appConfig from '../../../utils/config/appConfig';
import {Helmet} from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  headingLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  subheadingLabel: {
    marginBottom: theme.spacing(3),
  },
}));

const FullPageContainer = ({headingLabel, subheadingLabel, children}) => {
  const classes = useStyles(mainTheme);
  
  return (
    <>
      {headingLabel && <Helmet><title>{headingLabel.concat(' - ').concat(appConfig.titleSuffix)}</title></Helmet>}
      <Container maxWidth="md">
        <Typography className={classes.headingLabel} variant="h1">
          {headingLabel}
        </Typography>
        <Typography className={classes.subheadingLabel} variant="h2">
          {subheadingLabel}
        </Typography>
        {children}
      </Container>
    </>
  );
};

FullPageContainer.propTypes = {
  headingLabel: PropTypes.string.isRequired,
  subheadingLabel: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default FullPageContainer;
