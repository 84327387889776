import jwt_decode from 'jwt-decode';
import moment from 'moment';

const jwt = {
  getExpiryDate: (accessToken) => {
    const decoded = jwt_decode(accessToken);
    return moment.unix(decoded.exp).toDate();
  },

  isEmailVerified: (idToken) => {
    const decoded = jwt_decode(idToken);
    return Boolean(decoded.email_verified);
  },

  getEmail: (idToken) => {
    const decoded = jwt_decode(idToken);
    return decoded.email;
  },
};

export default jwt;
