import React, {createContext, useContext, useReducer} from 'react';
import signUpService from '../../../services/signUpService';
import _ from 'lodash';

// events
export const SignUpEvent = {
  EMAIL_SUBMITTED: 'EMAIL_SUBMITTED',
  SIGN_UP_CODE_SUBMITTED: 'SIGN_UP_CODE_SUBMITTED',
  PASSWORD_SUBMITTED: 'PASSWORD_SUBMITTED',
  PERSONAL_DETAILS_SUBMITTED: 'PERSONAL_DETAILS_SUBMITTED',
  RESEND_SIGN_UP_CODE_EMAIL_REQUESTED: 'RESEND_SIGN_UP_CODE_EMAIL_REQUESTED',
};

// flow states
export const SignUpFlowState = {
  ENTER_EMAIL: 'ENTER_EMAIL',
  ENTER_SIGN_UP_CODE: 'ENTER_SIGN_UP_CODE',
  ENTER_PERSONAL_DETAILS: 'ENTER_PERSONAL_DETAILS',
  ENTER_PASSWORD: 'ENTER_PASSWORD',
  SIGN_UP_SUCCESSFUL: 'SIGN_UP_SUCCESSFUL',
};

// initial state
const initialState = {
  flowState: SignUpFlowState.ENTER_EMAIL,
  email: null,
  signUpCode: null,
  password: null,
  submitting: false,
  errorMessage: null,
  infoMessage: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const signUpContext = createContext();

// provider
export const SignUpProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <signUpContext.Provider value={{state, dispatch}}>{children}</signUpContext.Provider>
  );
};

// hook
const useSignUp = () => {
  const {state, dispatch} = useContext(signUpContext);
  
  const addEvent = (event) => {
    switch (event.type) {
      case SignUpEvent.EMAIL_SUBMITTED:
        dispatch({
          flowState: SignUpFlowState.ENTER_EMAIL,
          submitting: true,
          errorMessage: null,
          infoMessage: null,
        });
        signUpService.submitSignUpEmail(event.payload.email)
          .subscribe(
          (_) =>
            dispatch({
              flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
              email: event.payload.email,
              submitting: false,
              errorMessage: null,
              infoMessage: null,
            }),
          (error) =>
            dispatch({
              flowState: SignUpFlowState.ENTER_EMAIL,
              submitting: false,
              errorMessage: error.message,
              infoMessage: null,
            })
        );
        break;
      case SignUpEvent.SIGN_UP_CODE_SUBMITTED:
        dispatch({
          flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
          submitting: true,
          errorMessage: null,
          infoMessage: null,
        });
        signUpService.submitSignUpCodeForEmail(event.payload.email, event.payload.signUpCode)
          .subscribe(
            (response) => {
              if (response.data) {
                // Sign up code was valid for email
                dispatch({
                  flowState: SignUpFlowState.ENTER_PASSWORD,
                  signUpCode: event.payload.signUpCode,
                  submitting: false,
                  errorMessage: null,
                  infoMessage: null,
                })
              } else {
                dispatch({
                  flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
                  submitting: false,
                  errorMessage: 'Incorrect sign up code',
                  infoMessage: null,
                })
              }
            },
            (error) => {
              dispatch({
                flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
                submitting: false,
                errorMessage: error.message,
                infoMessage: null,
              })
            }
          );
        break;
      case SignUpEvent.RESEND_SIGN_UP_CODE_EMAIL_REQUESTED:
        dispatch({
          flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
          submitting: true,
          errorMessage: null,
          infoMessage: null,
        });
        signUpService.submitSignUpEmail(state.email)
          .subscribe(
            (_) =>
              dispatch({
                flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
                submitting: false,
                errorMessage: null,
                infoMessage: `We sent an email to ${state.email}`,
              }),
            (error) =>
              dispatch({
                flowState: SignUpFlowState.ENTER_SIGN_UP_CODE,
                submitting: false,
                errorMessage: error.message,
                infoMessage: null,
              })
          );
        break;
      case SignUpEvent.PASSWORD_SUBMITTED:
        dispatch({
          flowState: SignUpFlowState.ENTER_PERSONAL_DETAILS,
          password: event.payload.password,
        });
        break;
      case SignUpEvent.PERSONAL_DETAILS_SUBMITTED:
        dispatch({
          flowState: SignUpFlowState.ENTER_PERSONAL_DETAILS,
          submitting: true,
          errorMessage: null,
          infoMessage: null,
        })
        const userDetails = {
          email: state.email,
          password: state.password,
          ...event.payload.personalDetails,
        }
        signUpService.createUser(userDetails, state.signUpCode).subscribe(
          (_) =>
            dispatch({
              flowState: SignUpFlowState.SIGN_UP_SUCCESSFUL,
              submitting: false,
              errorMessage: null,
              infoMessage: null,
            }),
          (error) =>
            dispatch({
              flowState: SignUpFlowState.ENTER_PERSONAL_DETAILS,
              submitting: false,
              errorMessage: error.message,
              infoMessage: null,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  
  return {
    state,
    addEvent,
  };
};

export default useSignUp;
