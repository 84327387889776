import React from 'react';
import {Redirect, Route, withRouter} from 'react-router';
import LoginContainer from '../components/LoginContainer/LoginContainer';
import paths, {DASHBOARD_PATH} from './paths';
import LoadingModal from '../commons/layout/LoadingModal';
import debounceRender from 'react-debounce-render';
import useAuthentication from '../hooks/useAuthentication';
import useAccountBalance from '../hooks/useAccountBalance';

const PrivateRoute = ({component: Component, path, history, ...rest}) => {
  const {initialised, user, authenticate, unauthenticate} = useAuthentication();
  const {loadAccountBalance} = useAccountBalance();
  const dashboardOverviewRoute = <Redirect to={paths.OVERVIEW} />;
  const dashboardRoute = <Route {...rest} render={(props) => <Component {...props} />} />;
  const spinningWheelRoute = <Route {...rest} component={debounceRender(LoadingModal, 1000, {leading: false})} />;
  const loginRoute = <Route {...rest} component={LoginContainer} />;

  // if not initialised yet!
  if (!initialised) {
    authenticate();
  }

  // authenticated
  // redirect to dashboard/overview if trying to access non dashboard page
  if (user) {
    // log out if user hits /logout path
    if (path === paths.LOGOUT) {
      unauthenticate();
      return loginRoute;
    }

    // load account balance and show user the page
    loadAccountBalance();
    return path.startsWith(DASHBOARD_PATH) ? dashboardRoute : dashboardOverviewRoute;
  }

  // if initialised and still not authenticated then need to login
  return !initialised && !user ? spinningWheelRoute : loginRoute;
};

export default withRouter(PrivateRoute);
