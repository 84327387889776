import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@material-ui/core';
import Spinner from 'react-spinkit';
import {makeStyles} from '@material-ui/styles';
import mainTheme from '../../themes/mainTheme';
import FourComponentBox from '../../commons/list/FourComponentBox';
import {MdChevronRight} from 'react-icons/all';
import DateTimeWithIcon from '../../utils/date/DateTimeWithIcon';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  activeIcon: {
    //color: theme.palette.primary.contrastText,
    display: 'inline',
    '& div': {
      width: theme.spacing(0.25),
      height: theme.spacing(0.25),
    },
    '& div:first-child': {
      display: 'none',
    },
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
  },
  address: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '70%',
  },
  totalCost: {
    fontWeight: 600,
    color: theme.palette.money.totalCost,
  },
  scheduleIcon: {
    fontSize: 18,
    marginRight: theme.spacing(0.75),
  },
}));

const TransactionListItemContent = ({transaction}) => {
  const classes = useStyles(mainTheme);

  const topLeft = <Typography className={classes.address}>{transaction.location.address}</Typography>;

  // top right
  const activeSign = (
    <Box className={classes.row}>
      <Spinner className={classes.activeIcon} name="ball-clip-rotate-multiple" fadeIn="none" />
      <Typography>Active</Typography>
    </Box>
  );
  const totalCost = <Typography className={classes.totalCost}>{transaction.payment.total === 0 ? 'FREE' : `$${transaction.payment.total.toFixed(2)}`}</Typography>;
  const bottomRight = transaction.status === 'ACTIVE' ? activeSign : totalCost;

  const bottomLeft = <DateTimeWithIcon dateTimeInMillis={transaction.session.startTime} />;
  const topRight = <MdChevronRight size={20} />;

  return <FourComponentBox topLeft={topLeft} topRight={topRight} bottomLeft={bottomLeft} bottomRight={bottomRight} />;
};

TransactionListItemContent.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionListItemContent;
