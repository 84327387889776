import {catchError, map} from 'rxjs/operators';
import {withAccessToken} from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import {errorMessageObservable} from '../utils/error/errorUtils';
import {ajax} from 'rxjs/ajax';
import {throwError} from 'rxjs';

export const creditCardService = {
  getCreditCards: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/credit-cards`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      }).pipe(
        map((response) =>
          response.response.data.map((creditCard) => {
            return {
              brand: creditCard.brand,
              lastFourDigits: creditCard.last_four_digits,
              expiryMonth: creditCard.expiry_month,
              expiryYear: creditCard.expiry_year,
              paymentMethodId: creditCard.payment_method_id,
            };
          })
        ),
        catchError((error) => errorMessageObservable(error))
      )
    ),
  addCreditCard: (paymentMethodId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV3}/credit-cards`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          payment_method_id: paymentMethodId,
        },
      }).pipe(
        map((response) => response.response),
        catchError((error) => {
          if (error.status === 409) {
            return throwError(new Error('You can only register one card to this account'));
          }
          return errorMessageObservable(error);
        })
      )
    ),
  removeCreditCard: (paymentMethodId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/credit-cards/${paymentMethodId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      }).pipe(
        map((response) => response.response),
        catchError((error) => errorMessageObservable(error))
      )
    ),
};
