import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {WarningDialog} from '../../../commons/dialogs/dialogs';
import {ManageCreditCardStep} from './ManageCreditCards';
import CreditCardPanel from '../../CreditCard/CreditCardPanel';

const useStyles = makeStyles((theme) => ({
  leftBox: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  removeButton: {
    marginTop: theme.spacing(4),
  },
}));

const ManageCreditCardsDetailsStep = ({creditCard, emitRemoveCreditCard, step}) => {
  const classes = useStyles(mainTheme);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const onRemoveButtonPressed = () => {
    setShowRemoveDialog(true);
  }

  const onRemoveCreditCard = () => {
    emitRemoveCreditCard(creditCard.paymentMethodId);
  };

  const onRemoveDialogClosed = () => setShowRemoveDialog(false);

  return (
    <>
      <Box classes={{root: classes.leftBox}}>
        <CreditCardPanel creditCard={creditCard} />
        <Button
          onClick={() => onRemoveButtonPressed()}
          disabled={step === ManageCreditCardStep.REMOVING_CREDIT_CARD}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          classes={{root: classes.removeButton}}
        >
          Remove
        </Button>
      </Box>
      <WarningDialog open={showRemoveDialog} buttonLabel="Confirm" buttonCallback={onRemoveCreditCard} onClose={onRemoveDialogClosed}>
        Are you sure you want to remove this credit card?
      </WarningDialog>
    </>
  );
};

ManageCreditCardsDetailsStep.propTypes = {
  creditCard: PropTypes.object.isRequired,
  emitRemoveCreditCard: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
};

export default ManageCreditCardsDetailsStep;
