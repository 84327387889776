import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  columnStack: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowCentered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ManageCreditCardsNoCreditCardStep = ({emitAddCreditCardRequest}) => {
  const classes = useStyles(mainTheme);
  return (
    <>
      <Box classes={{root: classes.columnStack}}>
        <Box classes={{root: classes.rowCentered}}>
          <Typography>There are no cards to display</Typography>
        </Box>
        <Box classes={{root: classes.rowCentered}}>
          <Button onClick={() => emitAddCreditCardRequest()} variant="contained" color="primary" size="large" fullWidth>
            ADD CARD
          </Button>
        </Box>
      </Box>
    </>
  );
};

ManageCreditCardsNoCreditCardStep.propTypes = {
  emitAddCreditCardRequest: PropTypes.func.isRequired,
};

export default ManageCreditCardsNoCreditCardStep;
