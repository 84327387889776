import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import PaperWithHeading from '../../commons/containers/PaperWithHeading/PaperWithHeading';
import {Divider, Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/styles';
import mainTheme from '../../themes/mainTheme';
import useAuthentication from '../../hooks/useAuthentication';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },
  label: {
  
  },
  value: {
    fontWeight: '600',
    maxWidth: '70%',
    overflowWrap: 'break-word',
    textAlign: 'end',
  },
  title: {
    textAlign: 'left',
    color: theme.palette.text.onBrightBackgroundUserBalance,
    padding: theme.spacing(1),
  }
}
));

const Profile = () => {
  const classes = useStyles(mainTheme);
  const {user} = useAuthentication();
  return (
    <Dashboard title={"Profile"}>
      <PaperWithHeading maxWidth='xs'>
          <Typography classes={{root: classes.title}} variant="h6">Personal Details</Typography>
          <Divider />
          <Detail label="First Name" value={user.firstName} />
          <Divider />
          <Detail label="Last Name" value={user.lastName} />
          <Divider />
          <Detail label="Email" value={user.email} />
          <Divider />
          <Detail label="Mobile Number" value={user.mobileNumber} />
          <Divider />
          <Detail label="Email Notifications" value={user.optedOutOfEmail ? "No" : "Yes"} />
      </PaperWithHeading>
    </Dashboard>
  );
};

const Detail = ({label, value}) => {
  const classes = useStyles(mainTheme);
  return (
    <Box className={classes.details}>
      <Typography classes={{root: classes.label}}>{label}</Typography>
      <Typography classes={{root: classes.value}}>{value}</Typography>
    </Box>
  );
}

export default Profile;

