import React, {createRef, useEffect} from 'react';
import useSignUp, {SignUpEvent} from './hook/useSignUp';
import _ from 'lodash';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import SmartTextField from '../../commons/form/SmartTextField';
import {Box, Button, ButtonBase, Typography} from '@material-ui/core';
import {ErrorBar, ProcessingBar, SuccessBar} from '../../commons/snackbars/snackbars';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(1),
  },
  button: {
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  resend: {
    marginTop: theme.spacing(1),
  },
  resendText: {
    textDecoration: 'underline',
  }
}));

const SignUpEnterSignUpCode = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useSignUp();
  const formikRef = createRef();
  useEffect(() => {
    if (state.submitting) {
      formikRef.current.setSubmitting(true);
    } else {
      formikRef.current.setSubmitting(false);
    }
  }, [state.submitting, formikRef]);
  
  const resendSignUpCodeEmail = () => {
    addEvent({type: SignUpEvent.RESEND_SIGN_UP_CODE_EMAIL_REQUESTED, payload: {}});
  }
  
  return (
    <FullPageContainer headingLabel="Sign Up">
      <>
        <Formik
          innerRef={formikRef}
          initialValues={{
            signUpCode: '',
          }}
          onSubmit={({signUpCode}) => {
            addEvent({
              type: SignUpEvent.SIGN_UP_CODE_SUBMITTED,
              payload: {
                signUpCode: signUpCode,
                email: state.email
              }
            });
          }}
          validationSchema={Yup.object({
            signUpCode: Yup.string().required('Required'),
          })}
        >
          {(formikProps) => (
            <Form>
              <Box classes={{root: classes.description}}>
                <Typography>An email with a sign up code was just sent to {state.email}.</Typography>
              </Box>
              <Field label="Sign Up Code" name="signUpCode" fullWidth autoFocus component={SmartTextField}/>
              <Button classes={{root: classes.button}} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                Verify
              </Button>
              <Box classes={{root: classes.resend}}>
                <Typography display="inline">Haven't received the code? </Typography>
                <ButtonBase onClick={() => resendSignUpCodeEmail()} disabled={state.submitting}>
                  <Typography classes={{root: classes.resendText}}>Resend</Typography>
                </ButtonBase>
              </Box>
            </Form>
          )}
        </Formik>
        <ProcessingBar open={state.submitting} fullPage/>
        <ErrorBar open={!_.isBlankString(state.errorMessage)} fullPage>{state.errorMessage}</ErrorBar>
        <SuccessBar open={!_.isBlankString(state.infoMessage)} fullPage>{state.infoMessage}</SuccessBar>
      </>
    </FullPageContainer>
  );
};

SignUpEnterSignUpCode.propTypes = {};

export default SignUpEnterSignUpCode;
