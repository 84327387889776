import { Observable, throwError } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import authenticationService, { getAccessToken } from '../../services/authenticationService';


export const loadAccessTokenObservable = () => new Observable((subscriber) => subscriber.next(getAccessToken()));
export const refreshAccessTokenIfExpired = () => (source) =>
  source.pipe(
    retryWhen((errors) =>
      errors.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if we have tried 3 times already, throw error
          if (retryAttempt > 2) {
            return throwError(error);
          }
          // retry if accessToken has expired or the call is a 401
          if (error.status === 401) {
            // unauthorized maybe due to access token expired
            return authenticationService.refreshAccessToken();
          }

          // Error status code was not unauthorized .: don't retry, just propagate the error
          return throwError(error);
        })
      )
    )
  );
