import React, {useContext, useState} from 'react';
import Dashboard from '../Dashboard/Dashboard';
import Tab from '@material-ui/core/Tab';
import ActivateChargeTag from './ActivateChargeTag/ActivateChargeTag';
import RequestChargeTagFlow from './RequestChargeTagFlow/RequestChargeTagFlow';
import {ChangeTagsFlowState, ChargeTagsContext} from './ChargeTags';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';

const ChargeTagTabs = () => {
  const setChangeTagsFlowState = useContext(ChargeTagsContext);

  const REQUEST_CHARGE_TAG = 'REQUEST_CHARGE_TAG';
  const ACTIVATE_CHARGE_TAG = 'ACTIVATE_CHARGE_TAG';

  const [tabValue, setTabValue] = useState(REQUEST_CHARGE_TAG);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dashboard title="Charge Tags">
      <>
        <TabContext value={tabValue}>
          <TabList onChange={handleChange} indicatorColor="secondary" textColor="primary">
            <Tab label="Request A Tag" value={REQUEST_CHARGE_TAG} />
            <Tab label="Activate Tag" value={ACTIVATE_CHARGE_TAG} />
          </TabList>
          <TabPanel value={REQUEST_CHARGE_TAG}>
            <RequestChargeTagFlow />
          </TabPanel>
          <TabPanel value={ACTIVATE_CHARGE_TAG}>
            <ActivateChargeTag activateSuccessHandler={() => setChangeTagsFlowState(ChangeTagsFlowState.ACTIVATED)} />
          </TabPanel>
        </TabContext>
      </>
    </Dashboard>
  );
};

ChargeTagTabs.propTypes = {};

export default ChargeTagTabs;
