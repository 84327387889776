import React from 'react';
import {ListItem, ListItemText} from '@material-ui/core';
import PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginBottom: theme.spacing(2),
    boxShadow: `0 2px 6px ${theme.palette.shadow.soft}, 0 2px 6px ${theme.palette.shadow.mid}, inset 0 1px 0 ${theme.palette.shadow.highlight}`,
    borderRadius: theme.spacing(0.5),
  },
}));

const ListItemWrapper = ({className, onClick, content}) => {
  const classes = useStyles(mainTheme);
  return (
    <ListItem classes={{root: clsx(classes.listItem, className)}} onClick={onClick}>
      <ListItemText primary={content} />
    </ListItem>
  );
};

ListItemWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  onClick: PropTypes.func,
};

export default ListItemWrapper;
