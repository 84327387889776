import React, {useState} from 'react';
import {Box, Button, Typography} from '@material-ui/core';
import chargeTagImage from '../../assets/images/openloop_charge_tag.png';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import Dashboard from '../Dashboard/Dashboard';
import chargeTagService from '../../services/chargeTagService';
import {ErrorBar} from '../../commons/snackbars/snackbars';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {WarningDialog} from '../../commons/dialogs/dialogs';
import PaperWithHeading from '../../commons/containers/PaperWithHeading/PaperWithHeading';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  chargeTagImage: {
    margin: theme.spacing(2),
    height: 150,
  },
  chargeTagId: {
    fontWeight: 'bold',
  },
  active: {
    fontWeight: 'bold',
    color: theme.palette.chargeTagStatus.active,
  },
  deactivated: {
    fontWeight: 'bold',
    color: theme.palette.chargeTagStatus.deactivated,
  },
  deactivateButton: {
    backgroundColor: theme.palette.chargeTagStatus.deactivated,
    color: theme.palette.text.onDarkBackgroundBright,
  },
  deleteButton: {
    backgroundColor: theme.palette.chargeTagStatus.deactivated,
    color: theme.palette.text.onDarkBackgroundBright,
  },
}));

const ChargeTagDetails = ({chargeTag, deactivateSuccessHandler, reactivateSuccessHandler, deleteSuccessHandler}) => {
  const classes = useStyles(mainTheme);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const deactivateHandler = () => {
    setIsSubmitting(true);
    chargeTagService.deactivateChargeTag(chargeTag.id).subscribe(
      (result) => {
        setIsSubmitting(false);
        setErrorMessage(null);
        deactivateSuccessHandler();
      },
      (error) => {
        setIsSubmitting(false);
        setErrorMessage(error.message);
      }
    );
  };

  const reactivateHandler = () => {
    setIsSubmitting(true);
    chargeTagService.reactivateChargeTag(chargeTag.id).subscribe(
      (result) => {
        setIsSubmitting(false);
        setErrorMessage(null);
        reactivateSuccessHandler();
      },
      (error) => {
        setIsSubmitting(false);
        setErrorMessage(error.message);
      }
    );
  };

  const showConfirmDeleteHandler = () => {
    setShowConfirmDelete(true);
  };

  const hideConfirmDeleteHandler = () => {
    setShowConfirmDelete(false);
  };

  const deleteHandler = () => {
    setShowConfirmDelete(false);
    setIsSubmitting(true);
    chargeTagService.deleteChargeTag(chargeTag.id).subscribe(
      (result) => {
        setIsSubmitting(false);
        setErrorMessage(null);
        deleteSuccessHandler();
      },
      (error) => {
        setIsSubmitting(false);
        setErrorMessage(error.message);
      }
    );
  };

  return (
    <Dashboard title="Charge Tags">
      <>
        <PaperWithHeading maxWidth="xs">
          <>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" justifyContent="center">
                <img className={classes.chargeTagImage} src={chargeTagImage} title="OpenLoop Charge Tag" alt="OpenLoop Charge Tag" />
              </Box>
              <Box>
                <Typography display="inline">Charge Tag ID: </Typography>
                <Typography display="inline" className={classes.chargeTagId}>
                  {chargeTag.id}
                </Typography>
              </Box>
              <Box>
                <Typography display="inline">Status: </Typography>
                <Typography display="inline" className={chargeTag.active ? classes.active : classes.deactivated}>
                  {chargeTag.active ? 'Active' : 'Deactivated'}
                </Typography>
              </Box>
            </Box>
            {chargeTag.active && (
              <Button
                className={clsx(classes.button, classes.deactivateButton)}
                type="button"
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                onClick={deactivateHandler}
                disabled={isSubmitting}
              >
                Deactivate
              </Button>
            )}
            {!chargeTag.active && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button className={classes.button} type="button" variant="contained" size="large" color="primary" fullWidth onClick={reactivateHandler} disabled={isSubmitting || showConfirmDelete}>
                  Reactivate
                </Button>
                <Button
                  className={clsx(classes.button, classes.deleteButton)}
                  type="button"
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={showConfirmDeleteHandler}
                  disabled={isSubmitting || showConfirmDelete}
                >
                  Delete
                </Button>
              </Box>
            )}
            <ErrorBar open={!_.isNull(errorMessage)}>{errorMessage}</ErrorBar>
            <WarningDialog open={showConfirmDelete} buttonLabel="Confirm" buttonCallback={deleteHandler} onClose={hideConfirmDeleteHandler}>
              Are you sure you want to delete the charge tag?
            </WarningDialog>
          </>
        </PaperWithHeading>
      </>
    </Dashboard>
  );
};

ChargeTagDetails.propTypes = {
  chargeTag: PropTypes.object.isRequired,
  deactivateSuccessHandler: PropTypes.func.isRequired,
  reactivateSuccessHandler: PropTypes.func.isRequired,
  deleteSuccessHandler: PropTypes.func.isRequired,
};

export default ChargeTagDetails;
