import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const EmptyListView = () => (
    <Box display="flex" justifyContent="center" padding={2}>
        <Typography>No records to display.</Typography>
    </Box>
);
export default EmptyListView;
