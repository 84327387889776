import React from 'react';
import {Helmet} from 'react-helmet-async';
import {getAnalytics, logEvent, setUserId, setUserProperties} from 'firebase/analytics';
import useAuthentication from '../../hooks/useAuthentication';
import userMetrics from '../../utils/user_metrics/userMetrics';

const FirebasePageAnalytics = () => {
  
  const {user} = useAuthentication();
  const logPageView = (newHelmetState) => {
    // If Helmet has updated the title, it will have a "title" field in the new state
    if (newHelmetState.title) {
      const analytics = getAnalytics()
      
      // Register the user for the page visit, if the user was authenticated when they hit this page
      if (user) {
        // Set the user id for things like BigQuery, or if we ever manage to make an audience in Firebase that can target this parameter
        // See https://firebase.google.com/docs/analytics/userid
        setUserId(analytics, user.id);
        
        // Set whether the user was a staff member, based on the email
        // See https://firebase.google.com/docs/analytics/user-properties?platform=web
        const isStaffMember = userMetrics.isStaffMember(user.email);
        setUserProperties(analytics, {'is_counties_staff_member': isStaffMember})
      }
      
      logEvent(analytics, 'page_view', {
        firebase_screen: newHelmetState.title
      });
    }
  };
  
  return (
    <Helmet onChangeClientState={(newState) => logPageView(newState)}></Helmet>
  );
  
};

FirebasePageAnalytics.propTypes = {};

export default FirebasePageAnalytics;