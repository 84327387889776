import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import {CheckboxWithLabel} from 'formik-material-ui';
import SmartPasswordField from '../../commons/form/SmartPasswordField';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';
import useSignUp, {SignUpEvent} from './hook/useSignUp';

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const SignUpEnterPassword = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useSignUp();

  const validatePassword = (password) => {
    const schema = Yup.string()
      .required('Password is required')
      .min(8, 'Must be at least eight characters')
      .matches(/^.*\d.*$/, 'Must contain at least one number')
      .matches(/^.*[a-z].*$/, 'Must contain at least one lowercase letter (a-z)')
      .matches(/^.*[A-Z].*$/, 'Must contain at least one uppercase letter (A-Z)');
    try {
      schema.validateSync(password);
      return undefined;
    } catch (validationError) {
      return validationError.message;
    }
  };
  
  const validateTermsAndConditionsCheck = (checked) => {
    if (!checked) {
      return 'Terms and Conditions must be agreed to';
    }
    return undefined;
  };

  return (
    <>
      <FullPageContainer headingLabel="Sign Up">
        <Formik
          initialValues={{
            emailAddress: state.email,
            password: '',
            repeatPassword: '',
            termsAndConditionsCheck: state.termsAndConditionsCheck ? state.termsAndConditionsCheck : false,
          }}
          validate={(values) => {
            const errors = {};
            if (values.password !== values.repeatPassword) {
              errors.repeatPassword = 'Password does not match';
            }
            return errors;
          }}
          onSubmit={({password}) => {
            addEvent({type: SignUpEvent.PASSWORD_SUBMITTED, payload: {password}});
          }}
        >
          {(formikProps) => (
            <>
              <Form>
                <Box classes={{root: classes.formRow}}>
                  <Field name="emailAddress" type="text">
                    {(fieldProps) => {
                      const {field, meta} = fieldProps;
                      return (
                        <>
                          <TextField
                            name={field.name}
                            onChange={field.onChange}
                            disabled={true}
                            value={field.value}
                            type="text"
                             variant="outlined"
                            label="Email Address"
                            fullWidth
                            error={meta.error && meta.touched}
                            autoFocus
                          />
                        </>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="emailAddress" component={FormHelperText} error />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name="password" component={SmartPasswordField} type="password" validate={validatePassword}  variant="outlined" label="Password" fullWidth showHint />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field name="repeatPassword" component={SmartPasswordField} type="password"  variant="outlined" label="Re-enter your password" fullWidth />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="termsAndConditionsCheck"
                    validate={validateTermsAndConditionsCheck}
                    Label={{
                      label: (
                        <Typography>
                          I have read and agree to the{' '}
                          <Link href="https://www.openloop.co.nz/terms-and-conditions" target="_blank" underline="always">
                            Terms and Conditions
                          </Link>{' '}
                          and the{' '}
                          <Link href="https://www.openloop.co.nz/privacy-policy" target="_blank" underline="always">
                            Privacy Policy
                          </Link>
                        </Typography>
                      ),
                    }}
                  />
                </Box>
                <Box classes={{root: classes.formRow}}>
                  <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={!(formikProps.isValid && formikProps.dirty)}>
                    Next
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </FullPageContainer>
    </>
  );
};

export default SignUpEnterPassword;
