import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import appConfig from '../../utils/config/appConfig';
import Dashboard from '../Dashboard/Dashboard';
import ManageCreditCards from './ManageCreditCards/ManageCreditCards';

const stripePromise = loadStripe(`${appConfig.paymentGatewayKey}`);

const WalletTabs = () => {

  return (
    <Dashboard title={'Payment Method'}>
      <Elements stripe={stripePromise}>
        <ManageCreditCards />
      </Elements>
    </Dashboard>
  );
};

WalletTabs.propTypes = {};

export default WalletTabs;
