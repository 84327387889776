import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import CountrySelect from './CountrySelect';
import {TextField} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  phoneFieldBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  phoneTextFieldLabelShrink: {
    marginLeft: -50 - theme.spacing(1),
  },
}));

const SmartPhoneField = ({field, form, variant, label, fullWidth, InputLabelProps, disabled, ...rest}) => {
  const classes = useStyles(mainTheme);
  const [phoneNumber, setPhoneNumber] = useState('+64');
  const textFieldInputRef = useRef(null);

  const handleCountryChanged = (newCountry) => {
    const prefixNumber = newCountry.phone;
    setPhoneNumber(`+${prefixNumber}`);
    textFieldInputRef.current.focus();
  };

  const touched = form.touched[field.name];
  const errorMessage = form.errors[field.name];
  const displayError = touched && !_.isNil(errorMessage);

  return (
    <>
      <Box classes={{root: classes.phoneFieldBox}}>
        <CountrySelect variant={variant} notifyValueUpdated={handleCountryChanged} error={displayError} disabled={disabled || form.isSubmitting} />
        <TextField
          {...rest}
          variant={variant}
          inputRef={textFieldInputRef}
          onChange={(event) => {
            // filter out alphabetical characters
            const newValue = '+'.concat(_.replace(event.target.value, /[^\d]/g, ''));
            form.setFieldValue(field.name, newValue, true);
            setPhoneNumber(newValue);
          }}
          onBlur={() => {
            form.setFieldTouched(field.name, true, true);
          }}
          value={phoneNumber}
          fullWidth={fullWidth}
          error={displayError}
          label={label}
          InputLabelProps={{
            ...InputLabelProps,
            classes: {
              shrink: classes.phoneTextFieldLabelShrink,
            },
          }}
          disabled={disabled || form.isSubmitting}
        />
      </Box>
      {touched && errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
};

SmartPhoneField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SmartPhoneField;
