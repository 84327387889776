import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';

const GenericLoadingStep = ({observableFunction, successCallback}) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // TODO investigate if the useLoadingContainerWithErrorPanel hook can be used instead
  useEffect(() => {
    const subscription = observableFunction().subscribe(
      (response) => {
        setLoading(false);
        successCallback(response);
      },
      (error) => {
        setErrorMessage(error.message);
        setHasError(true);
        setLoading(false);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [observableFunction, successCallback]);

  return (
    <>
      <LoadingContainerWithErrorPanel loading={loading} hasError={hasError} errorMessage={errorMessage}>
        <></>
      </LoadingContainerWithErrorPanel>
    </>
  );
};

GenericLoadingStep.propTypes = {
  observableFunction: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
};

export default GenericLoadingStep;
