import {ajax} from 'rxjs/ajax';
import appConfig from '../utils/config/appConfig';
import {catchError, map} from 'rxjs/operators';
import {withAccessToken} from '../utils/auth/authenticationUtils';
import {throwError} from 'rxjs';
import InvalidChargeTagIdException from './exceptions/InvalidChargeTagIdException';
import ChargeTagAlreadyActiveException from './exceptions/ChargeTagAlreadyActiveException';
import ActiveChargeTagsLimitExceededException from './exceptions/ActiveChargeTagsLimitExceededException';

const chargeTagService = {
  getChargeTags: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => response.response.data),
      catchError((error) => throwError(new Error('Unable to retrieve charge tags')))
    ),
  requestChargeTag: (address) => {
    const addressArray = address.postcode ? [address.street, address.suburb, address.city, address.postcode] : [address.street, address.suburb, address.city];
    const addressString = addressArray.join(', ');
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/requests`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          postalAddress: addressString,
        },
      })
    ).pipe(
      map((response) => response),
      catchError((error) => throwError(new Error('An error occurred. Please try again.')))
    );
  },
  activateChargeTag: (chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          id: chargeTagId,
        },
      })
    ).pipe(
      catchError((error) => {
        if (error.status === 400) {
          return throwError(new InvalidChargeTagIdException());
        }

        if (error.status === 409) {
          return throwError(new ChargeTagAlreadyActiveException());
        }

        if (error.status === 451) {
          return throwError(new ActiveChargeTagsLimitExceededException());
        }

        return throwError(new Error('Unable to activate charge tag! Please try again.'));
      })
    ),
  deactivateChargeTag: (chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/${chargeTagId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          active: false,
        },
      })
    ).pipe(catchError((error) => throwError(new Error('Unable to deactivate charge tag! Please try again.')))),
  reactivateChargeTag: (chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/${chargeTagId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          active: true,
        },
      })
    ).pipe(catchError((error) => throwError(new Error('Unable to reactivate charge tag! Please try again.')))),
  deleteChargeTag: (chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/charge-tags/${chargeTagId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(catchError((error) => throwError(new Error('Unable to delete charge tag! Please try again.')))),
};

export default chargeTagService;
