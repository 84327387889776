const userMetrics = {
  isStaffMember: (email) => {
    if (!email) {
      return false;
    }
    const lowerCase = email.toLowerCase()
    if (lowerCase.includes('@countiespower.com')) {
      return true;
    }
    // noinspection RedundantIfStatementJS
    if (lowerCase.includes('@countiesenergy.co.nz')) {
      return true;
    }
    return false;
  }
}

export default userMetrics;