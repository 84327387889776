import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 0,
    '& .StripeElement': {
      width: '100%',
    },
  },
  fieldSet: {
    borderStyle: 'none',
    willChange: 'opacity, transform',
    padding: 0,
    margin: 0,
    // boxShadow: `0 6px 9px ${theme.palette.shadow.soft}, 0 2px 5px ${theme.palette.shadow.mid}, inset 0 1px 0 ${theme.palette.shadow.inset}`,
    // borderRadius: theme.spacing(0.5),
  },
  formRow: {
    display: 'flex',
    justifyContent: 'center',

    paddingTop: theme.spacing(2),
  },
  formRowCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  formErrorRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formRowSingleElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.shadow.inset}`,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  title: {
    textAlign: 'left',
    color: theme.palette.text.onBrightBackgroundUserBalance,
  }

}));

const CardDetailsForm = ({ emitPaymentMethodCreated, title, buttonLabel }) => {
  const classes = useStyles(mainTheme);

  // the following classes are used in the Stripe iframe. The names cannot be changed.
  const iframeStyles = {
    base: {
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (submitting) {
      // we are already submitting the form.
      return;
    }

    // we don't want multiple submits at the same time
    setSubmitting(true);
    setFormError(null);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      setFormError(error.message);
      setSubmitting(false);
      return;
    }

    // notify the parent component that we got a new payment method
    emitPaymentMethodCreated(paymentMethod.id);
  };

  const handleChange = (event) => {
    if (event.error) {
      setFormError(event.error.message);
    } else {
      setFormError(null);
    }
  };

  return (
    <Box classes={{ root: classes.rootContainer }}>
      <form onSubmit={handleSubmit}>
        <fieldset className={classes.fieldSet}>
          <Typography className={classes.title} variant='h6' >{title}&nbsp;</Typography>
          <Box className={classes.formRow}>
            <CardNumberElement options={{ style: iframeStyles }} onChange={handleChange} />
          </Box>
          <Box className={classes.formRow}>
            <CardExpiryElement options={{ style: iframeStyles }} onChange={handleChange} />
            <CardCvcElement options={{ style: iframeStyles }} onChange={handleChange} />
          </Box>
          {formError ? (
            <>
              <div className={classes.formErrorRow}>
                <Typography classes={{ root: classes.errorText }}>{formError}</Typography>
              </div>
            </>
          ) : null}
          <Box className={classes.formRow}>
            <Button type="submit" variant="contained" disabled={!stripe && submitting} color="primary" size="large"
              fullWidth>
              {buttonLabel}
            </Button>
          </Box>
        </fieldset>
      </form>
    </Box>
  );
};

CardDetailsForm.propTypes = {
  emitPaymentMethodCreated: PropTypes.func.isRequired,
};

export default CardDetailsForm;
