import {Box} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const HorizontalCenter = ({children}) => (
  <Box display="flex" justifyContent="center">
    {children}
  </Box>
);

HorizontalCenter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HorizontalCenter;
