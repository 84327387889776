import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  titleRowMobile: {
    display: 'flex',
    position: 'fixed',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  }
}));

const Heading = ({title, variant}) => {
  const classes = useStyles(mainTheme);
  return (
    <>
      <Box classes={{root: isMobile ? classes.titleRowMobile : classes.titleRow}}>
        <Typography variant={variant}>{title}</Typography>
      </Box>
    </>
  );
};

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

Heading.defaultProps = {
  variant: 'h1',
};

export default Heading;
