import React from 'react';
import PropTypes from 'prop-types';
import CardDetailsForm from './CardDetailsForm';

const ManageCreditCardsAddingCreditCardStep = ({emitNewCreditCardDetails}) => {
  const onPaymentMethodCreated = (paymentMethodId) => {
    emitNewCreditCardDetails(paymentMethodId);
  };

  return (
    <>
      <CardDetailsForm title="Add Card" buttonLabel="ADD CARD" emitPaymentMethodCreated={onPaymentMethodCreated} />
    </>
  );
};

ManageCreditCardsAddingCreditCardStep.propTypes = {
  emitNewCreditCardDetails: PropTypes.func.isRequired,
};

export default ManageCreditCardsAddingCreditCardStep;
