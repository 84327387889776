import {withAccessToken} from '../utils/auth/authenticationUtils';
import {ajax} from 'rxjs/ajax';
import appConfig from '../utils/config/appConfig';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';

const toTransaction = (emspTransaction) => ({
  id: emspTransaction.id,
  status: emspTransaction.status,
  user: {
    chargeTagId: emspTransaction.user.charge_tag_id,
  },
  location: {
    id: emspTransaction.location.id,
    address: emspTransaction.location.address,
    owner: emspTransaction.location.owner,
  },
  session: {
    durationInSeconds: emspTransaction.session.duration_in_seconds,
    kwh: emspTransaction.session.kwh,
    startTime: emspTransaction.session.start_date_time,
  },
  tariff: {
    ratePerKwh: emspTransaction.tariff.rate_per_kwh,
    ratePerMinute: emspTransaction.tariff.rate_per_minute,
    unlockFee: emspTransaction.tariff.unlock_fee,
  },
  payment: {
    kwhFeeTotal: emspTransaction.payment.kwh_fee_total,
    minuteFeeTotal: emspTransaction.payment.minute_fee_total,
    unlockFeeTotal: emspTransaction.payment.unlock_fee_total,
    total: emspTransaction.payment.total,
  },
  paymentBreakdown: {
    prepaidAmount: emspTransaction.payment_breakdown?.prepaid_amount ?? 0,
    creditCardPayment: emspTransaction.payment_breakdown?.credit_card_payment ?? 0,
  },
});

const toTransactionCardDetails = (emspCardDetails) => ({
  cardDetails: {
    brand: emspCardDetails.brand,
    lastFourDigits: emspCardDetails.last_four_digits,
  },
});

const toTransactions = (emspTransactions) => emspTransactions.map(toTransaction);

const transactionService = {
  getTransactions: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV3}/transactions`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => toTransactions(response.response.data)),
      catchError((error) => throwError(new Error('Unable to fetch transactions!')))
    ),
  getTransaction: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV3}/transactions/${id}`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => toTransaction(response.response)),
      catchError((error) => throwError(new Error(`Unable to fetch the transaction with id ${id}!`)))
    ),
  getTransactionCardDetails: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomainV3}/transactions/${id}/card-details`,
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => toTransactionCardDetails(response.response)),
      catchError((error) => throwError(new Error(`Unable to fetch the transaction card details with id ${id}!`)))
    ),
};

export default transactionService;
