import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withRouter} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {MdChevronRight} from 'react-icons/all';
import {Typography} from '@material-ui/core';
import _ from 'lodash';
import * as openloopIconPack from '../../assets/openloopIconPack';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.dark,
    '&$listItemSelected, &$listItemSelected:hover': {
      backgroundColor: theme.palette.drawerItem.selectedBackgroundColor,
      color: theme.palette.drawerItem.selectedTextColor,
    },
  },
  listItemIcon: {
    paddingBottom: 2,
    marginRight: theme.spacing(1),
    minWidth: 24,
    color: theme.palette.primary.dark,
  },
  drawerText: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
  // DO NOT REMOVE THIS: This class is tied to the selected menu item and used inside root to increase specificity
  listItemSelected: {
    '& $listItemIcon': {
      color: theme.palette.drawerItem.selectedTextColor,
    },
    '& $drawerText': {
      color: theme.palette.drawerItem.selectedTextColor,
    },
  },
}));

const LeftDrawerMenuItem = ({label, icon, path, history, location, linkIconEnabled = true}) => {
  const classes = useStyles(mainTheme);
  
  const isExternalLink = _.startsWith(path, 'http');
  
  const onClickHandler = (event) => {
    if (isExternalLink) {
      window.open(path);
      return;
    }
    
    event.preventDefault();
    history.push(path);
  };
  
  return (
    <ListItem
      classes={{
        root: classes.root,
        selected: classes.listItemSelected,
      }}
      button
      key={label}
      onClick={onClickHandler}
      selected={location.pathname.includes(path)}
    >
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" className={classes.drawerText}>
            {label}
          </Typography>
        }
      />
      {getLinkIcon(isExternalLink, linkIconEnabled)}
    </ListItem>
  );
};

const getLinkIcon = (isExternalLink, enabled) => {
  if (!enabled) {
    return null;
  }
  
  if (isExternalLink) {
    return <openloopIconPack.externalLink size={16}/>;
  }
  
  return <MdChevronRight size={20}/>;
}

LeftDrawerMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
};

export default withRouter(LeftDrawerMenuItem);
