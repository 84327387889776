import React from 'react';
import Login from '../Login/Login';
import tokenStorage from '../../utils/storage/tokenStorage';
import jwt from '../../utils/jwt/jwt';
import useAuthentication from '../../hooks/useAuthentication';

const LoginContainer = () => {
  const {authenticate} = useAuthentication();

  const handleUsernamePasswordAuthenticated = (authResult) => {
    // clear any existing access token and save the new one
    tokenStorage.clear();
    tokenStorage.setKey(jwt.getEmail(authResult.idToken));
    tokenStorage.save(authResult);

    // update the authentication context which refreshes the App which in turn refreshes PrivateRoute
    // to redirect the page to the dashboard
    authenticate();
  };

  return <Login setUsernamePasswordAuthenticated={handleUsernamePasswordAuthenticated} />;
};

export default LoginContainer;
