import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import openloopCarsImage from '../../../assets/images/openloop_cars.png';
import {useHistory} from 'react-router';
import Button from '@material-ui/core/Button';
import paths from '../../../routes/paths';
import PaperWithHeading from '../../../commons/containers/PaperWithHeading/PaperWithHeading';

const useStyles = makeStyles((theme) => ({
  openloopCarsImage: {
    width: '160px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  row: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  rowCenter: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  rowSection: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.tab.divider}`,
  },
}));

const RequestChargeTagSuccess = (props) => {
  const classes = useStyles(mainTheme);
  const history = useHistory();
  return (
    <PaperWithHeading>
      <Box classes={{root: classes.titleContainer}}>
        <Typography variant="h2">Request Submitted</Typography>
      </Box>
      <Box classes={{root: classes.rowCenter}}>
        <Typography>Your OpenLoop Charge Tag will be shipped to you shortly.</Typography>
      </Box>
      <Box classes={{root: classes.rowCenter}}>
        <img className={classes.openloopCarsImage} src={openloopCarsImage} title="OpenLoop Cars Image" alt="OpenLoop Cars" />
      </Box>
      <Box classes={{root: classes.rowCenter}}>
        <Typography>We aim to process all Charge Tag requests within 14 days.</Typography>
      </Box>
      <Box classes={{root: classes.rowCenter}}>
        <Typography>Thank you.</Typography>
      </Box>
      <Box classes={{root: classes.rowSection}}>
        <Button onClick={() => history.push(paths.HOME)} variant="contained" color="primary" fullWidth>
          Home
        </Button>
      </Box>
    </PaperWithHeading>
  );
};

RequestChargeTagSuccess.propTypes = {};

export default RequestChargeTagSuccess;
