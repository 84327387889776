import React from 'react';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import {useHistory, useLocation} from 'react-router-dom';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import * as PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import _ from 'lodash';

const TransactionDesktopView = ({transactions}) => {
  const history = useHistory();
  const location = useLocation();

  const cellStyle = (data, rowData) =>
    rowData.status === 'ACTIVE'
      ? {
          backgroundColor: mainTheme.palette.background.connectorStatus.charging,
          color: mainTheme.palette.text.onDarkBackgroundBright,
        }
      : {};

  const totalCostCellStyle = (data, rowData) =>
    rowData.status === 'ACTIVE'
      ? {
          fontWeight: 'bold',
          backgroundColor: mainTheme.palette.background.connectorStatus.charging,
          color: mainTheme.palette.money.totalCost,
          textAlign: 'left',
          width: 120,
        }
      : {
          fontWeight: 'bold',
          color: mainTheme.palette.money.totalCost,
          textAlign: 'left',
          width: 120,
        };

  const handleClick = (id) => {
    history.push(`${location.pathname}/${id}`);
  };

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Charge Tag Id',
            field: 'chargeTagId',
            cellStyle: cellStyle,
            width: 'auto',
            hidden: true,
            hiddenByColumnsButton: true,
            export: true,
          },
          {
            title: 'Started Charging At',
            field: 'startTime',
            defaultSort: 'desc',
            cellStyle: cellStyle,
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.startTime),
            customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.startTime), filter),
            export: false,
          },
          {
            title: 'Started Charging At',
            field: 'readableStartTime',
            defaultSort: 'desc',
            hidden: true,
            export: true,
          },
          {title: 'Address', field: 'address', cellStyle: cellStyle, width: 'auto'},
          {title: 'Status', field: 'status', cellStyle: cellStyle},
          {title: 'Kwh', field: 'kwh', type: 'numeric', cellStyle: cellStyle},
          {
            title: 'Duration',
            field: 'duration',
            type: 'numeric',
            cellStyle: cellStyle,
            render: (rowData) => <>{dateTimeFormatter.printReadableTimeFromSeconds(rowData.duration)}</>,
          },
          {title: 'Total', field: 'total', type: 'currency', align: 'left', cellStyle: totalCostCellStyle},
        ]}
        data={transactions.map((transaction) => ({
          id: transaction.id,
          chargeTagId: transaction.user.chargeTagId,
          startTime: transaction.session.startTime,
          readableStartTime: dateTimeFormatter.printReadableDateTime(transaction.session.startTime),
          address: transaction.location.address,
          status: transaction.status,
          kwh: transaction.session.kwh,
          duration: transaction.session.durationInSeconds,
          total: transaction.payment.total.toFixed(2),
        }))}
        onRowClick={(event, rowData) => handleClick(rowData.id)}
        exportFileName="Transactions"
      />
    </>
  );
};

TransactionDesktopView.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default TransactionDesktopView;
