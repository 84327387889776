import {getObject, getValue, putObject, putValue, remove} from './secureStorage';
import TokenNotFoundError from '../auth/exceptions/TokenNotFoundError';
import InvalidTokenError from '../auth/exceptions/InvalidTokenError';

const KEY = 'token-key';
const KEY_PREFIX = 'TOKEN';
let _key;

const tokenStorage = {
  setKey(username) {
    _key = `${KEY_PREFIX}-${username}`;
    putValue(KEY, _key);
  },

  _getKey() {
    if (_key == null) {
      _key = getValue(KEY);
    }
    return _key;
  },

  get(checkAccessToken = true) {
    const token = getObject(this._getKey());
    if (token == null) {
      throw new TokenNotFoundError('Token holder not found!');
    }

    if (checkAccessToken) {
      if (token.accessToken == null) {
        // TODO create a logger class which uses console.log in combination with chalk
        console.log('Valid access token not found!');
        throw new InvalidTokenError('Valid access token not found!');
      }
    }

    return token;
  },

  save(token) {
    putObject(this._getKey(), token);
  },

  clear() {
    remove(this._getKey());
    remove(KEY);
  },

  update(idToken, accessToken, expiresIn) {
    const currentToken = this.get(false);
    const updatedToken = Object.assign(currentToken, {
      accessToken,
      idToken,
      expiresIn,
    });
    this.save(updatedToken);
  },
};

export default tokenStorage;
