import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import paths from '../../routes/paths';
import logo from '../../assets/images/openloop_logo_white.png';
import carImage from '../../assets/images/electric-car.png';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import LeftDrawerMenuItem from './LeftDrawerMenuItem';
import {CardMedia, Divider, Drawer, SwipeableDrawer} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {MdMenu} from 'react-icons/all';
import useAuthentication from '../../hooks/useAuthentication';
import useAccountBalance from '../../hooks/useAccountBalance';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import * as openloopIconPack from '../../assets/openloopIconPack';
import Heading from '../Heading/Heading';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    width: theme.leftDrawer.width,
    backgroundColor: theme.palette.drawer.backgroundColor,
    borderRightColor: theme.palette.secondary.main,
    borderRightWidth: '0',
    boxShadow: `0px 2px 4px -1px ${theme.palette.shadow.soft}, 0px 4px 5px 0px ${theme.palette.shadow.soft}, 0px 1px 10px 0px ${theme.palette.shadow.soft}`,
  },
  drawerToggleButton: {
    zIndex: 2,
  },
  topContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  logoContainer: {
    height: 69,
    bottom: 10,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '@media (max-height: 498px)': {
      position: 'initial',
    }
  },
  logo: {
    height: 40,
    marginBottom: -5,
  },
  carImage: {
    height: 60,
    marginTop: theme.spacing(2),
    marginRight: 10,
  },
  carImageContainer: {
    height: 69,
    display: "flex",
    position: "relative",
    top: 10,
  },
  listContainer: {
    padding: '0',
  },
  divider: {
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  logoDivider: {
    backgroundColor: theme.palette.drawer.divider,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  nameContainer: {
    marginLeft: theme.spacing(2),
  },
  nameText: {
    fontWeight: 300,
    lineHeight: 1.3,
    fontSize: 27,
    color: theme.palette.text.onBrightBackground,
  },
  balanceContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  balanceText: {
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 20,
    color: theme.palette.text.onBrightBackgroundUserBalance,
  },
  balanceTextAmount: {
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 20,
    color: theme.palette.text.onBrightBackgroundUserBalance,
  },
  titleBar: {
    backgroundColor: theme.palette.drawer.titleBar,
    display: 'flex',
    flexDirection: 'row',
    position: "fixed",
    width: '100%',
    alignItems: 'center',
    boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
    height: 50,
    zIndex: 10,
  },
  hamBurgerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const _getGreeting = () => {
  let date = new Date(Date.now());
  let hour = date.getHours();
  if (hour < 12) {
    return 'Morning';
  }
  if (hour < 17) {
    return 'Afternoon';
  }
  return 'Evening';
}

const LeftDrawer = ({isMobileView, title}) => {
  const {user} = useAuthentication();
  const {accountBalance} = useAccountBalance();
  const classes = useStyles(mainTheme);
  const [open, setOpen] = useState(false);
  
  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    setOpen(open);
  };
  
  const menuItems = (
    <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List className={classes.listContainer}>
        <Divider className={classes.divider} />
        <LeftDrawerMenuItem label={'Profile'} icon={<openloopIconPack.profile size={20} />} path={paths.PROFILE} />
        <LeftDrawerMenuItem label={'Wallet'} icon={<openloopIconPack.topUp size={20} />} path={paths.WALLET} />
        <LeftDrawerMenuItem label={'Transactions'} icon={<openloopIconPack.transactionHistory size={20} />} path={paths.TRANSACTIONS} />
        <LeftDrawerMenuItem label={'Charge Tags'} icon={<openloopIconPack.requestATag size={20} />} path={paths.CHARGE_TAGS} />
        <LeftDrawerMenuItem label={'FAQs'} icon={<openloopIconPack.faqs size={20} />} path={paths.FAQS} />
        <LeftDrawerMenuItem label={'About Us'} icon={<openloopIconPack.openloop size={20} />} path={paths.ABOUT_US} />
        <LeftDrawerMenuItem label={'Log Out'} icon={<openloopIconPack.logout size={20} />} path={paths.LOGOUT} linkIconEnabled={false} />
      </List>
    </Box>
  );
  
  const topContainer = (
    <Box className={classes.topContainer}>
      <CardMedia className={classes.carImageContainer}>
        <Box classes={{root: classes.nameContainer}}>
          <Typography variant="h3" classes={{root: classes.nameText}}>
            {_getGreeting()} {user.firstName}
          </Typography>
        </Box>
        <img src={carImage} className={classes.carImage} title="OpenLoop Logo" alt="OpenLoop Logo"/>
      </CardMedia>
      <Divider className={classes.logoDivider}/>
      
      <Box classes={{root: classes.balanceContainer}}>
        <Typography variant="h3" classes={{root: classes.balanceText}} display="inline">
          Your Balance:&nbsp;&nbsp;
        </Typography>
        <Typography variant="h2" classes={{root: classes.balanceTextAmount}} display="inline">
          ${_.isNil(accountBalance) ? '' : accountBalance.amount.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
  
  const mobileView = () => (
    <Box>
      <Box className={classes.titleBar}>
        <Button className={classes.drawerToggleButton} onClick={toggleDrawer(true)}>
          <MdMenu className={classes.hamBurgerIcon} size={24} color="black"/>
        </Button>
        <Heading title={title}/>
      </Box>
      <SwipeableDrawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {topContainer}
        {menuItems}
        <CardMedia className={classes.logoContainer}>
          <img src={logo} className={classes.logo} title="OpenLoop Logo" alt="OpenLoop Logo"/>
        </CardMedia>
      </SwipeableDrawer>
    </Box>
  );
  
  const browserView = () => (
    <Drawer variant="permanent" classes={{paper: classes.drawerPaper}}>
      {topContainer}
      {menuItems}
      <CardMedia className={classes.logoContainer}>
        <img src={logo} className={classes.logo} title="OpenLoop Logo" alt="OpenLoop Logo"/>
      </CardMedia>
    </Drawer>
  );
  
  return isMobileView ? mobileView() : browserView();
};

export default LeftDrawer;
