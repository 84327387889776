import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import chargeTagImage from '../../../assets/images/openloop_charge_tag.png';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithHeading from "../../../commons/containers/PaperWithHeading/PaperWithHeading";
import SmartTextField from '../../../commons/form/SmartTextField';
import { ErrorBar } from '../../../commons/snackbars/snackbars';
import { useLoadingContainerWithErrorPanel } from '../../../hooks/useLoadingContainerWithErrorPanel';
import chargeTagService from '../../../services/chargeTagService';
import userService from '../../../services/userService';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  chargeTagImage: {
    height: 200,
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
  },
  formRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formRowCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formNoteRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    textAlign: 'left',
    color: theme.palette.text.onBrightBackgroundUserBalance,
  }
}));

const RequestChargeTag = ({ notifyRequestSent }) => {
  const classes = useStyles(mainTheme);
  const [user, setUser] = useState({});

  const [showErrorBar, setShowErrorBar] = useState(false);
  const [errorBarMessage, setErrorBarMessage] = useState(null);

  const memoizedObservable = useMemo(() => userService.getCurrentUser(), []);
  const onSuccessCallback = useCallback((response) => setUser(response), []);
  const onErrorCallback = useCallback(() => setUser({}), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(memoizedObservable, onSuccessCallback, onErrorCallback);

  return (
    <>
      <PaperWithHeading heading="Request a Charge Tag">
        <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
          <>
            <Formik
              initialValues={{
                fullName: user.name,
                mobileNumber: user.mobileNumber,
                street: '',
                suburb: '',
                city: '',
                postcode: '',
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                fullName: Yup.string().required(),
                mobileNumber: Yup.string().required(),
                street: Yup.string().required('Required'),
                suburb: Yup.string().required('Required'),
                city: Yup.string().required('Required'),
                postcode: Yup.string().default(''),
              })}
              onSubmit={(values, actions) => {
                setErrorBarMessage(null);
                setShowErrorBar(false);
                chargeTagService.requestChargeTag(values).subscribe(
                  () => {
                    notifyRequestSent();
                  },
                  (error) => {
                    setErrorBarMessage(error.message);
                    setShowErrorBar(true);
                    actions.setSubmitting(false);
                  }
                );
              }}
            >
              {(formikProps) => (
                <>
                  <Form>
                    <Box classes={{ root: classes.formRowCenter }}>
                      <img className={classes.chargeTagImage} src={chargeTagImage} title="OpenLoop Charge Tag Image" alt="OpenLoop Charge Tag" />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Typography>
                        Charge Tags can be used as an alternative method to activate charging sessions. You can still continue using the OpenLoop app to monitor your charging sessions, receive charger
                        notifications and view you transaction history.
                      </Typography>
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.fullName} label="Name" name="fullName" type="fullName" component={SmartTextField} variant="outlined" fullWidth disabled />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.mobileNumber} label="Mobile Number" name="mobileNumber" type="mobileNumber" component={SmartTextField} variant="outlined" fullWidth disabled />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.street} label="Street" name="street" type="street" component={SmartTextField} variant="outlined" fullWidth />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.suburb} label="Suburb" name="suburb" type="suburb" component={SmartTextField} variant="outlined" fullWidth />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.city} label="City" name="city" type="city" component={SmartTextField} variant="outlined" fullWidth />
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Field className={classes.postcode} label="Postcode" name="postcode" type="postcode" component={SmartTextField} variant="outlined" fullWidth />
                    </Box>
                    <Box classes={{ root: classes.formNoteRow }}>
                      <Typography variant="body1">Note: We aim to process all Charge Tag requests within 14 days</Typography>
                    </Box>
                    <Box classes={{ root: classes.formRow }}>
                      <Button type="submit" variant="contained" color="primary" disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting} fullWidth>
                        Submit
                      </Button>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </>
        </LoadingContainerWithErrorPanel>
      </PaperWithHeading>
      <ErrorBar open={showErrorBar && errorBarMessage}>{errorBarMessage}</ErrorBar>
    </>
  );
};

RequestChargeTag.propTypes = {
  notifyRequestSent: PropTypes.func.isRequired,
};

export default RequestChargeTag;
