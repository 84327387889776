import React, {useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Typography} from '@material-ui/core';
import mainTheme from '../../themes/mainTheme';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Spinner from 'react-spinkit';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  desktopWithDrawer: {
    transform: `translateX(-50%) translateX(${theme.leftDrawer.width / 2}px)`,
  },
  alertText: {
    color: theme.palette.text.onDarkBackgroundBright,
    fontSize: 16,
    textAlign: 'left',
  },
  processingBarContent: {
    backgroundColor: theme.palette.processingBar.backgroundColor,
  },
  processingBarText: {
    color: theme.palette.primary.contrastText,
    display: 'inline',
  },
  processingBarThreeDots: {
    color: theme.palette.primary.contrastText,
    display: 'inline',
    '& div': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
    },
  },
}));

const SlideTransition = (props) => <Slide {...props} direction="up" />;

/**
 * - Show snackbar when there is no drawer menu e.g. Login, Forgot Password and Sign Up
 * We have to add "fullPage" attribute to let snackbar know it should position itself horizontally in the middle of the page no matter what.
 *
 * Example:
 * <pre>
 *   <code>
 *     <ErrorBar open={true} fullPage>Error Message</ErrorBar>
 *   </code>
 * </pre>
 *
 * - Show snackbar when there is a drawer menu e.g. All authenticated screens
 * We should have no "fullPage" attribute to let snackbar know it should position itself horizontally in the middle
 * then shift to the right 50% of the drawer's width. This way it is positioned horizontally in the middle of the left component of the page.
 *
 * Example:
 * <pre>
 *   <code>
 *     <ErrorBar open={true}>Error Message</ErrorBar>
 *   </code>
 * </pre>
 *
 * @param
 * - type: comes in 4 options: success, info, warning and error
 * - children is the child components
 * - fullPage if exists the snackbar will get centered in the middle of the page, otherwise the snackbar will shift itself to the left
 * half of width of the drawer
 *
 * @returns Snackbar
 */
const SmartSnackbar = ({type, children, fullPage, onClose = () => {}}) => {
  const classes = useStyles(mainTheme);
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    setOpen(false);
    onClose();
  };

  return (
    <Snackbar className={clsx({[classes.desktopWithDrawer]: !isMobile && !fullPage})} open={open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={SlideTransition}>
      <Alert className={classes.alertText} elevation={6} variant="filled" onClose={handleClose} severity={type}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export const ProcessingBar = ({open, text = 'Processing', fullPage}) => {
  const classes = useStyles(mainTheme);

  return open ? (
    <>
      <Snackbar className={clsx({[classes.desktopWithDrawer]: !isMobile && !fullPage})} open={open} TransitionComponent={SlideTransition}>
        <SnackbarContent
          classes={{root: classes.processingBarContent}}
          message={
            <>
              <Typography classes={{root: classes.processingBarText}}>{text}</Typography>
              <Spinner className={classes.processingBarThreeDots} name="three-bounce" fadeIn="none" />
            </>
          }
        />
      </Snackbar>
    </>
  ) : (
    <></>
  );
};

export const SuccessBar = ({open, children, fullPage, onClose}) => (open ? <SmartSnackbar type="success" children={children} fullPage={fullPage} onClose={onClose} /> : <></>);
export const WarningBar = ({open, children, fullPage, onClose}) => (open ? <SmartSnackbar type="warning" children={children} fullPage={fullPage} onClose={onClose} /> : <></>);
export const ErrorBar = ({open, children, fullPage, onClose}) => (open ? <SmartSnackbar type="error" children={children} fullPage={fullPage} onClose={onClose} /> : <></>);
export const InfoBar = ({open, children, fullPage, onClose}) => (open ? <SmartSnackbar type="info" children={children} fullPage={fullPage} onClose={onClose} /> : <></>);
