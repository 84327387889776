import CryptoJS from 'crypto-js';
import appConfig from '../config/appConfig';

export const getValue = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue == null) {
    return null;
  }

  const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, `${appConfig.secureStorageSecretKey}`).toString(CryptoJS.enc.Utf8);
  return decryptedValue;
};

export const putValue = (key, value) => {
  const encryptedValue = CryptoJS.AES.encrypt(value, `${appConfig.secureStorageSecretKey}`).toString();
  return localStorage.setItem(key, encryptedValue);
};

export const getObject = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue == null) {
    return null;
  }

  const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, `${appConfig.secureStorageSecretKey}`).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedValue);
};

export const putObject = (key, object) => {
  const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(object), `${appConfig.secureStorageSecretKey}`).toString();
  return localStorage.setItem(key, encryptedValue);
};

export const remove = (key) => {
  localStorage.removeItem(key);
};

export const clear = () => {
  localStorage.clear();
};
