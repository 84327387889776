import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import mainTheme from '../../../themes/mainTheme';
import {Box, Container, IconButton} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import _ from 'lodash';

const useStyles = (theme, backgroundColor) =>
  makeStyles((theme) => ({
    container: {
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    paper: {
      width: '100%',
      borderRadius: 4,
      padding: theme.spacing(2),
      textAlign: 'center',
      backgroundColor: backgroundColor,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    heading: {
      textAlign: 'left',
      color: theme.palette.text.onBrightBackgroundUserBalance,
    }
  }));

const PaperWithHeading = ({heading, onEdit, children, maxWidth, backgroundColor}) => {
  const classes = useStyles(mainTheme, backgroundColor)();
  return (
    <Container classes={{root: classes.container}} disableGutters maxWidth={_.isNil(maxWidth) ? false : maxWidth}>
      <Paper classes={{root: classes.paper}}>
        <>
          <Box classes={{root: classes.header}}>
            {heading && <Typography className={classes.heading} variant="h6">{heading}</Typography>}
            {onEdit && (
              <IconButton onClick={onEdit}>
                <Edit />
              </IconButton>
            )}
          </Box>
          {children}
        </>
      </Paper>
    </Container>
  );
};

PaperWithHeading.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.element.isRequired,
  onEdit: PropTypes.func,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default PaperWithHeading;
