import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import UnauthorisedException from '../utils/auth/exceptions/UnauthorisedException';
import appConfig from '../utils/config/appConfig';

const loginService = {
  handleSubmit: (email, password) =>
    ajax({
      url: `${appConfig.iasApiDomain}/auth/oauth/access-token`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: {
        username: email,
        password: password,
      },
    }).pipe(
      map((response) => {
        const token = response.response;
        return {
          accessToken: token.access_token,
          refreshToken: token.refresh_token,
          expiresIn: token.expires_in,
          idToken: token.id_token,
          tokenType: token.token_type,
        };
      }),
      catchError((error) => {
        if (error.status === 401) {
          return throwError(new UnauthorisedException('The username or password you entered is incorrect'));
        }

        if (error.status === 403) {
          return throwError(new UnauthorisedException('Admin access is required to log into OpenLoop console'));
        }
        return throwError(new UnauthorisedException('Error authorising'));
      })
    ),
};

export default loginService;
