import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/images/openloop_logo_white.png';
import SmartPasswordField from '../../commons/form/SmartPasswordField';
import LoadingModal from '../../commons/layout/LoadingModal';
import { ErrorBar } from '../../commons/snackbars/snackbars';
import mainTheme from '../../themes/mainTheme';
import UnauthorisedException from '../../utils/auth/exceptions/UnauthorisedException';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import * as PropTypes from 'prop-types';
import PaperWithHeading from '../../commons/containers/PaperWithHeading/PaperWithHeading';
import SmartTextField from '../../commons/form/SmartTextField';
import Center from '../../commons/layout/Center';
import HorizontalCenter from '../../commons/layout/HorizontalCenter';
import OpenLoopPaper from '../../commons/layout/OpenLoopPaper';
import paths from '../../routes/paths';
import loginService from '../../services/loginService';
import appConfig from '../../utils/config/appConfig';
import jwt from '../../utils/jwt/jwt';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  logo: {
    width: '20em',
    paddingTop: theme.spacing(2),
  },
  root: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    margin: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  forgottenPasswordBox: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  signUpBox: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  message: {
    width: 150,
    fontSize: 20,
    marginTop: theme.spacing(2),
    display: 'inline-block',
  },
}));

const Login = ({ setUsernamePasswordAuthenticated }) => {
  const classes = useStyles(mainTheme);

  const [failedLogin, setFailedLogin] = useState(false);
  const [failedLoginErrorMessage, setFailedLoginErrorMessage] = useState('');

  const handleAuthResult = (authResult) => {
    const idToken = authResult.idToken;

    if (!idToken) {
      setFailedLogin(true);
      setFailedLoginErrorMessage('Login service failed to return id token');
      return;
    }

    if (!jwt.isEmailVerified(idToken)) {
      setFailedLogin(true);
      return;
    }

    setUsernamePasswordAuthenticated(authResult);
  };

  return (
    <OpenLoopPaper>
      <Helmet>
        <title>{"Login".concat(' - ').concat(appConfig.titleSuffix)}</title>
      </Helmet>
      <Center>
        <PaperWithHeading maxWidth="xs">
          <>
            <HorizontalCenter>
              <img className={classes.logo} src={logo} alt={'OpenLoop'} />
              <br />
            </HorizontalCenter>
            <text className={classes.message}>
              Hassle-free way to stay charged
            </text>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={({ email, password }, { setSubmitting }) => {
                // reset failed login
                setFailedLogin(false);
                setFailedLoginErrorMessage('');

                // submit
                loginService.handleSubmit(email, password).subscribe(
                  (result) => {
                    setSubmitting(false);
                    handleAuthResult(result);
                  },
                  (error) => {
                    setSubmitting(false);
                    if (error instanceof UnauthorisedException) {
                      setFailedLogin(true);
                      setFailedLoginErrorMessage(error.message);
                    }
                  }
                );
              }}
              validationSchema={Yup.object({
                email: Yup.string().required('Required').email('Invalid email address'),
                password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters').matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'Invalid Password'),
              })}
            >
              {(formikProps) => (
                <>
                  <Form>
                    <br />
                    <Field label="Email" name="email" type="email" fullWidth component={SmartTextField} variant='outlined' />
                    <br />
                    <br />
                    <Field label="Password" name="password" type="password" fullWidth component={SmartPasswordField} variant='outlined' />
                    <br />
                    <Button className={classes.button} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                      Login
                    </Button>
                  </Form>
                  <LoadingModal open={formikProps.isSubmitting} />
                </>
              )}
            </Formik>

            <Box classes={{ root: classes.forgottenPasswordBox }}>
              <Typography variant="body2">
                <Link href={paths.FORGOT_PASSWORD} underline="none">
                  Forgot Password
                </Link>
              </Typography>
            </Box>
            <Box classes={{ root: classes.signUpBox }}>
              <Typography variant="body2">
                Dont have an account?{' '}
                <Link href={paths.SIGN_UP} underline="none">
                  Sign Up
                </Link>
              </Typography>
            </Box>
            <ErrorBar open={failedLogin && failedLoginErrorMessage} fullPage>
              {failedLoginErrorMessage}
            </ErrorBar>
          </>
        </PaperWithHeading>
      </Center>
    </OpenLoopPaper>
  );
};

Login.propTypes = {
  setUsernamePasswordAuthenticated: PropTypes.func.isRequired,
};

export default Login;
