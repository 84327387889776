import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import routes from '../../routes/routes';
import {AuthenticationProvider} from '../../hooks/useAuthentication';
import {AccountBalanceProvider} from '../../hooks/useAccountBalance';

const App = () => {
  return (
    <>
      <CssBaseline />
      <React.StrictMode>
        <main>
          <AuthenticationProvider>
              <AccountBalanceProvider>{routes}</AccountBalanceProvider>
          </AuthenticationProvider>
        </main>
      </React.StrictMode>
    </>
  );
};

export default App;
