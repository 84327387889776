import {ajax} from 'rxjs/ajax';
import appConfig from '../utils/config/appConfig';
import {catchError, delay, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {errorMessageObservable} from '../utils/error/errorUtils';
import moment from 'moment';

const signUpService = {
  submitSignUpEmail: (email) => {
    return ajax({
      url: `${appConfig.emspApiDomainV3}/sign-up/email-verification`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: {
        email: email,
      },
    }).pipe(
      catchError((_) => {
        return throwError(new Error('Failed to send email verification'));
      })
    );
  },
  submitSignUpCodeForEmail: (email, signUpCode) => {
    return ajax({
      url: `${appConfig.emspApiDomainV3}/sign-up/code-validation-decision`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-OPL-SIGN-Up-Code': signUpCode,
      },
      body: {
        email,
      },
    }).pipe(
      // We introduce a delay to make the user think something is happening...
      delay(2000),
      map((response) => response.response),
      catchError((error) => errorMessageObservable(error))
    );
  },
  createUser: (userDetails, signUpCode) => {
    const dob = moment("1970 01 01", "YYYY MM DD").format('DD/MM/YYYY');
    const requestBody = {
      dob: dob,
      email: userDetails.email,
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      password: userDetails.password,
      mobile_number: userDetails.mobileNumber,
      opted_out_of_email: !userDetails.optInToDiscountsAndNews,
    };
    let url = `${appConfig.emspApiDomainV3}/users`;
    return ajax({
      url: url,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-OPL-Sign-Up-Code': signUpCode,
      },
      body: requestBody,
    }).pipe(
      map((response) => response.response),
      catchError(() => throwError(new Error('An unexpected error has occurred, please try again')))
    );
  },
};

export default signUpService;
