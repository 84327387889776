import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import LeftDrawer from './LeftDrawer';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Heading from '../Heading/Heading';
import {isMobile} from 'react-device-detect';
import {Helmet} from "react-helmet-async";
import appConfig from '../../utils/config/appConfig';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    height: '100vh',
  },
  right: {
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
}));

const Dashboard = ({children, title}) => {
  const classes = useStyles(mainTheme);
  let _isMobile = isMobile;
  return (
    <>
      {title && <Helmet><title>{title.concat(' - ').concat(appConfig.titleSuffix)}</title></Helmet>}
      <Box className={classes.container}>
        <Box>
          <LeftDrawer isMobileView={_isMobile} title={title}/>
        </Box>
        <Box className={classes.right}>
          <Box className={classes.content}>
            {!_isMobile ? <Heading title={title}/> : <Box height={40}/>}
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Dashboard;
