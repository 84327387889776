import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import mainTheme from '../../themes/mainTheme';
import clsx from 'clsx';
import _ from 'lodash';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  twoColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

const FourComponentBox = ({topLeft, topRight, bottomLeft, bottomRight}) => {
  const classes = useStyles(mainTheme);

  const hasBottoms = !_.isNil(bottomLeft) || !_.isNil(bottomRight);

  return (
    <Box>
      <Box classes={{root: classes.twoColumns}}>
        {topLeft}
        {topRight}
      </Box>
      {hasBottoms && (
        <Box classes={{root: clsx(classes.twoColumns, classes.marginTop)}}>
          {bottomLeft}
          {bottomRight}
        </Box>
      )}
    </Box>
  );
};

FourComponentBox.propTypes = {
  topLeft: PropTypes.element,
  topRight: PropTypes.element,
  bottomLeft: PropTypes.element,
  bottomRight: PropTypes.element,
};

export default FourComponentBox;
