import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import HighlightablePanel from '../../commons/containers/HighlightablePanel/HighlightablePanel';
import mainTheme from '../../themes/mainTheme';
import CreditCardEmoji from './credit_card_emoji';

const useStyles = makeStyles((theme) => ({
  creditCardPanelColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: 40,
  },
  creditCardPanelRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  creditCardPanelRowEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rowContentRight: {
    marginLeft: theme.spacing(1),
    fontSize: '12px',
  },
  creditCardText: {
    color: theme.palette.creditCard.text,
    textAlign: 'right',
    letterSpacing: 1.1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  creditCardSecondaryText: {
    fontSize: '0.8rem',
    color: theme.palette.creditCard.text,
  },
  lastFourDigits: {
    marginLeft: theme.spacing(1),
  },
  title: {
    textAlign: 'left',
    color: theme.palette.text.onBrightBackgroundUserBalance,
    paddingBottom: theme.spacing(1),
  }
}));

const CreditCardPanel = ({ creditCard, highlighted, onPressed }) => {
  const classes = useStyles(mainTheme);

  const friendlyExpiryMonth = _.padStart(`${creditCard.expiryMonth}`, 2, '0');
  const expiryYear = `${creditCard.expiryYear}`;
  const friendlyExpiryYear = expiryYear.substring(expiryYear.length - 2);

  return (
    <>
      <Typography className={classes.title} variant="h6">Your Saved Card</Typography>
      <HighlightablePanel highlighted={highlighted} onPressed={onPressed}>
        <Box classes={{ root: classes.creditCardPanelColumn }}>
          <Box classes={{ root: classes.creditCardPanelRow }}>
            <CreditCardEmoji brand={creditCard.brand} />
            <Box className={classes.creditCardText}>
              <Typography classes={{ root: `${classes.rowContentRight} ${classes.creditCardText}` }}>●●●● ●●●● ●●●● </Typography>
              <Typography classes={{ root: `${classes.lastFourDigits}` }}>{creditCard.lastFourDigits}</Typography>
            </Box>
          </Box>
          <Box classes={{ root: classes.creditCardPanelRowEnd }}>
            <Typography classes={{ root: classes.creditCardSecondaryText }}>
              EXPIRES {friendlyExpiryMonth}/{friendlyExpiryYear}
            </Typography>
          </Box>
        </Box>
      </HighlightablePanel>
    </>
  );
};

CreditCardPanel.defaultProps = {
  highlighted: false,
  onPressed: () => {
  },
};

CreditCardPanel.propTypes = {
  creditCard: PropTypes.object.isRequired,
};

export default CreditCardPanel;
