import ScheduleIcon from '@material-ui/icons/Schedule';
import {Box, Typography} from '@material-ui/core';
import dateTimeFormatter from './dateTimeFormatter';
import React from 'react';
import PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  scheduleIcon: {
    fontSize: 18,
    marginRight: theme.spacing(0.75),
  },
}));

const DateTimeWithIcon = ({dateTimeInMillis}) => {
  const classes = useStyles(mainTheme);
  return (
    <Box className={classes.row}>
      <ScheduleIcon className={classes.scheduleIcon} />
      <Typography>{dateTimeFormatter.printReadableDateTime(dateTimeInMillis)}</Typography>
    </Box>
  );
};

DateTimeWithIcon.propTypes = {
  dateTimeInMillis: PropTypes.number.isRequired,
};

export default DateTimeWithIcon;
