import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';
import SmartTextField from '../../commons/form/SmartTextField';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../commons/snackbars/snackbars';
import authenticationService from '../../services/authenticationService';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  email: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles(mainTheme);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailure, setFailure] = useState(false);

  return (
    <FullPageContainer headingLabel="Forgot Password">
      <>
        <Typography>We will send you an email to reset your password.</Typography>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={({ email }, { setSubmitting }) => {
            authenticationService.resetPassword(email).subscribe(
              (response) => {
                setSubmitting(false);
                setSuccess(true);
                setFailure(false);
              },
              (error) => {
                setSubmitting(false);
                setSuccess(false);
                setFailure(true);
              }
            );
          }}
          validationSchema={Yup.object({
            email: Yup.string().required('Required').email('Invalid email address'),
          })}
        >
          {(formikProps) => (
            <>
              <Form>
                <Field className={classes.email} label="Email" name="email" type="email" component={SmartTextField} variant="outlined" fullWidth />
                <Button type="submit" variant="contained" color="primary" disabled={!(formikProps.isValid && formikProps.dirty)} fullWidth>
                  Send Email
                </Button>
              </Form>
              <ProcessingBar open={formikProps.isSubmitting} fullPage />
              <SuccessBar open={isSuccess} fullPage>
                A password reset email will arrive shortly.
              </SuccessBar>
              <ErrorBar open={isFailure} fullPage>
                Unable to submit change password request.
              </ErrorBar>
            </>
          )}
        </Formik>
      </>
    </FullPageContainer>
  );
};

export default ForgotPassword;
