import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {Typography} from '@material-ui/core';
import visaImage from '../../assets/images/cards/visa.png';
import mastercardImage from '../../assets/images/cards/mastercard.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
  },
  text: {
    color: theme.palette.creditCard.text,
  },
}));

const CreditCardEmoji = ({brand, size}) => {
  const classes = useStyles(mainTheme);

  if (!brand) {
    return <CreditCardIcon fontSize={28} />;
  }

  const uppercaseBrand = brand.toUpperCase();
  switch (uppercaseBrand) {
    case 'VISA':
      return <img className={classes.logo} style={{height: size}} src={visaImage} title="visa" alt="visa" />;
    case 'MASTERCARD':
      return <img className={classes.logo} style={{height: size}} src={mastercardImage} title="mastercard" alt="mastercard" />;
    default:
      return <Typography className={{root: classes.text}}>{uppercaseBrand}</Typography>;
  }
};

CreditCardEmoji.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default CreditCardEmoji;
