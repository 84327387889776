import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';

const userService = {
  getCurrentUser: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/users/current`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => errorMessageObservable(error))
    ),

  getCurrentAccount: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspApiDomain}/users/current/account`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => errorMessageObservable(error))
    )
};

export default userService;
