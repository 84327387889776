import React from 'react';
import {tableIcons} from './tableIcons';
import MaterialTable from 'material-table';
import mainTheme from '../../themes/mainTheme';
import PropTypes from 'prop-types';

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

const MaterialTableWrapper = ({filtering, paginationType, searchText, debounceInterval, pageSize, exportFileName, ...rest}) => (
  <MaterialTable
    style={{
      paddingTop: mainTheme.spacing(1),
      fontSize: '1rem',
    }}
    icons={tableIcons}
    options={{
      headerStyle: {
        backgroundColor: mainTheme.palette.primary.main,
        fontWeight: 'bold',
      },
      searchFieldAlignment: 'left',
      searchFieldStyle: {
        color: mainTheme.palette.primary.dark,
        marginTop: mainTheme.spacing(2),
        marginBottom: mainTheme.spacing(2),
      },
      showTitle: false,
      pageSize: pageSize,
      pageSizeOptions: ROWS_PER_PAGE_OPTIONS,
      exportButton: true,
      exportAllData: true,
      exportFileName: exportFileName,
      columnsButton: true,
      filtering: filtering,
      debounceInterval: debounceInterval,
      searchText: searchText,
      paginationType: paginationType,
      emptyRowsWhenPaging: false,
      thirdSortClick: false,
    }}
    {...rest}
  />
);

MaterialTableWrapper.defaultProps = {
  filtering: true,
  paginationType: 'normal',
  pageSize: ROWS_PER_PAGE_OPTIONS[0],
};

MaterialTableWrapper.propTypes = {
  filtering: PropTypes.bool,
  paginationType: PropTypes.string,
  pageSize: PropTypes.number,
  exportFileName: PropTypes.string.isRequired,
  debounceInterval: PropTypes.number,
  searchText: PropTypes.string,
};

export default MaterialTableWrapper;
