import React from 'react';
import ListWrapper from '../../commons/list/ListWrapper';
import {useHistory, useLocation} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import ListItemWrapper from '../../commons/list/ListItemWrapper';
import TransactionListItemContent from './TransactionListItemContent';
import {makeStyles} from '@material-ui/styles';
import mainTheme from '../../themes/mainTheme';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.background.connectorStatus.charging,
    boxShadow: `0 2px 4px ${theme.palette.background.connectorStatus.charging}, 0 2px 4px ${theme.palette.background.connectorStatus.charging}, inset 0 1px 0 ${theme.palette.background.connectorStatus.charging}`,
    '& *': {
      color: 'white',
    },
  },
}));

const TransactionsMobileView = ({transactions}) => {
  const classes = useStyles(mainTheme);

  const history = useHistory();
  const location = useLocation();

  const handleClick = (id) => {
    history.push(`${location.pathname}/${id}`);
  };

  return (
    <ListWrapper>
      {transactions.map((transaction) => (
        <ListItemWrapper
          className={clsx({[classes.active]: transaction.status === 'ACTIVE'})}
          key={transaction.id}
          onClick={() => handleClick(transaction.id)}
          content={<TransactionListItemContent transaction={transaction} />}
        />
      ))}
    </ListWrapper>
  );
};

TransactionsMobileView.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default TransactionsMobileView;
