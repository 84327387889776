/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import mainTheme from '../../../themes/mainTheme';

// ISO 3166-1 alpha-2
// No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
}

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: 50,
    marginRight: theme.spacing(1),
  },
  paper: {
    width: 300,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const countries = [
  {code: 'AU', label: 'Australia', phone: '61'},
  {code: 'NZ', label: 'New Zealand', phone: '64'},
];

const CountrySelect = ({variant, notifyValueUpdated, error, disabled}) => {
  const classes = useStyles(mainTheme);

  return (
    <Autocomplete
      disableClearable
      defaultValue={countries[1]} // defa// ult to NZ
      options={countries}
      onChange={(event, newValue) => {
        notifyValueUpdated(newValue);
      }}
      classes={{
        root: classes.autocomplete,
        paper: classes.paper,
        option: classes.option,
      }}
      getOptionLabel={(option) => countryToFlag(option.code)}
      renderOption={(option) => (
        <>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code}) +{option.phone}
        </>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant={variant}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
            error={error}
          />
        );
      }}
      disabled={disabled}
    />
  );
};

CountrySelect.propTypes = {
  variant: PropTypes.string,
  notifyValueUpdated: PropTypes.func.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CountrySelect;
