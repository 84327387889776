import React, {createRef, useEffect} from 'react';
import useSignUp, {SignUpEvent} from './hook/useSignUp';
import _ from 'lodash';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import SmartTextField from '../../commons/form/SmartTextField';
import {Box, Button, Typography} from '@material-ui/core';
import {ErrorBar, ProcessingBar} from '../../commons/snackbars/snackbars';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(1),
  },
  button: {
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const SignUpEnterEmail = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useSignUp();
  const formikRef = createRef();
  useEffect(() => {
    if (!state.submitting) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.submitting, formikRef]);
  return (
    <FullPageContainer headingLabel="Sign Up">
      <>
        <Formik
          innerRef={formikRef}
          initialValues={{
            email: '',
          }}
          onSubmit={({email}) => {
            addEvent({type: SignUpEvent.EMAIL_SUBMITTED, payload: {email}});
          }}
          validationSchema={Yup.object({
            email: Yup.string().required('Required').email('Invalid email address'),
          })}
        >
          {(formikProps) => (
            <Form>
              <Box classes={{root: classes.description}}>
                <Typography>Please enter your email address to register an account with OpenLoop.</Typography>
              </Box>
              <Field label="Email" name="email" fullWidth autoFocus component={SmartTextField}/>
              <Button classes={{root: classes.button}} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
        <ProcessingBar open={state.submitting} fullPage/>
        <ErrorBar open={!_.isBlankString(state.errorMessage)} fullPage>{state.errorMessage}</ErrorBar>
      </>
    </FullPageContainer>
  );
};

SignUpEnterEmail.propTypes = {};

export default SignUpEnterEmail;
