
import { ajax } from 'rxjs/ajax';
import { tap } from "rxjs/operators";
import appConfig from "../utils/config/appConfig";
import tokenStorage from "../utils/storage/tokenStorage";

export const getRefreshToken = () => tokenStorage.get().refreshToken;
export const getAccessToken = () => tokenStorage.get().accessToken;

const authenticationService = {
    /**
     * Refreshes the access token by making a POST request to the authentication endpoint.
     *
     * @return {Observable} An observable that emits the response from the server.
     */
    refreshAccessToken: () => {
        return ajax({
            method: 'POST',
            url: `${appConfig.iasApiDomain}/auth/oauth/refresh-access-token`,
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
            },
            body: { refresh_token: getRefreshToken() }
        }).pipe(
            tap((response) => {
                console.log('SUCCEEDED: Refresh access token');
                const token = tokenStorage.get();
                token.idToken = response.response.id_token;
                token.accessToken = response.response.access_token;
                token.expiresIn = response.response.expires_in;
                tokenStorage.save(token);
            }),
        )
    },
    /**
     * Resets the password for a given user
     *
     * @param {string} email - The email address of the user.
     * @return {Promise} A promise that resolves with the response from the server.
     */
    resetPassword: (email) => {
        return ajax({
            url: `${appConfig.iasApiDomain}/auth/reset-password`,
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: { email: email },
        })
    }
}

export default authenticationService;