import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {useHistory} from 'react-router';
import paths from '../../routes/paths';
import FullPageContainer from '../../commons/containers/FullPageContainer/FullPageContainer';

const useStyles = makeStyles((theme) => ({
  formRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const SignUpSuccessful = () => {
  const classes = useStyles(mainTheme);
  const history = useHistory();
  
  return (
    <FullPageContainer headingLabel="Sign Up">
      <>
        <Box classes={{root: classes.formRow}}>
          <Typography>Your OpenLoop account has been created.</Typography>
        </Box>
        <Box>
          <Typography>Please tap the button below to login.</Typography>
        </Box>
        <Box classes={{root: classes.formRow}}>
          <Button variant="contained" color="primary" size="large" fullWidth onClick={() => history.replace(paths.HOME)}>
            Login
          </Button>
        </Box>
      </>
    </FullPageContainer>
  );
};

export default SignUpSuccessful;
