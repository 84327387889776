import React, {createContext, useContext, useReducer} from 'react';
import userService from '../services/userService';
import _ from 'lodash';

// action types
export const actionTypes = {
  loadAccountBalance: 'loadAccountBalance',
};

// initial state
const initialState = {
  accountBalance: null,
  accountBalanceLoaded: false,
};

// reducer
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.loadAccountBalance:
      return {
        ...state,
        accountBalance: action.accountBalance,
        accountBalanceLoaded: true,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// context
const accountBalanceContext = createContext(initialState);

// provider
export const AccountBalanceProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    // provide {state, dispatch} object to all children
    <accountBalanceContext.Provider value={{state, dispatch}}>{children}</accountBalanceContext.Provider>
  );
};

// hook
const useAccountBalance = () => {
  const {state, dispatch} = useContext(accountBalanceContext);

  const _loadAccountBalance = () => {
    userService.getCurrentAccount().subscribe(
      (result) => {
        if (!_.isEqual(result, state.accountBalance)) {
          dispatch({
            type: actionTypes.loadAccountBalance,
            accountBalance: result,
          });
        }
      },
      (error) => console.log('Unable to obtain account balance!')
    );
  };

  const loadAccountBalance = () => {
    if (state.accountBalanceLoaded) {
      return;
    }

    _loadAccountBalance();
  };

  const refreshAccountBalance = () => _loadAccountBalance();

  return {
    accountBalance: state.accountBalance,
    loadAccountBalance,
    refreshAccountBalance,
  };
};

export default useAccountBalance;
