import React, {useState} from 'react';
import RequestChargeTag from './RequestChargeTag';
import RequestChargeTagSuccess from './RequestChargeTagSuccess';

const RequestChargeTagFlow = (props) => {
  const ADDRESS_DETAILS = 'ADDRESS_DETAILS';
  const REQUEST_SENT = 'REQUEST_SENT';

  const [step, setStep] = useState(ADDRESS_DETAILS);

  const handleRequestSent = () => {
    setStep(REQUEST_SENT);
  };

  let component;
  switch (step) {
    case ADDRESS_DETAILS:
      component = <RequestChargeTag notifyRequestSent={handleRequestSent} />;
      break;
    case REQUEST_SENT:
      component = <RequestChargeTagSuccess />;
      break;
    default:
      throw new Error('Unknown request charge tag step: ' + step);
  }

  return <>{component}</>;
};

RequestChargeTagFlow.propTypes = {};

export default RequestChargeTagFlow;
