import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import LoginContainer from '../components/LoginContainer/LoginContainer';
import paths from './paths';
import GenericNotFound from '../components/GenericNotFound/GenericNotFound';
import PrivateRoute from './PrivateRoute';
import Overview from '../components/Overview/Overview';
import WalletTabs from '../components/TopUpTabs/WalletTabs';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import ChargeTags from '../components/ChargeTags/ChargeTags';
import Transactions from '../components/TransactionHistory/Transactions';
import Transaction from '../components/TransactionHistory/Transaction';
import SignUpFlow from '../components/SignUp/SignUpFlow';
import Profile from '../components/Profile/Profile';
import FirebasePageAnalytics from '../components/FirebasePageAnalytics/FirebasePageAnalytics';

const routes = (
  <Router>
    <FirebasePageAnalytics/>
    <Switch>
      <Redirect exact from={paths.HOME} to={paths.LOGIN} />
      <PrivateRoute exact path={paths.LOGIN} component={LoginContainer} />
      <Route exact path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={paths.SIGN_UP} component={SignUpFlow} />
      <PrivateRoute exact path={paths.LOGOUT} component={LoginContainer} />
      <PrivateRoute exact path={paths.OVERVIEW} component={Overview} />
      <PrivateRoute exact path={paths.WALLET} component={WalletTabs} />
      <PrivateRoute exact path={paths.CHARGE_TAGS} component={ChargeTags} />
      <PrivateRoute exact path={paths.TRANSACTIONS} component={Transactions} />
      <PrivateRoute exact path={paths.TRANSACTION} component={Transaction} />
      <PrivateRoute exact path={paths.PROFILE} component={Profile} />
      <Route exact path="*" component={GenericNotFound} />
    </Switch>
  </Router>
);
export default routes;
