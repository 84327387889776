import {TextField} from 'formik-material-ui';
import React from 'react';

const SmartTextField = ({inputClass, ...rest}) => (
  <TextField
    InputProps={{
      className: inputClass,
    }}
    {...rest}
  />
);
export default SmartTextField;
