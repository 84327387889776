export const DASHBOARD_PATH = '/dashboard';

const paths = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  SIGN_UP: '/signup',
  OVERVIEW: `${DASHBOARD_PATH}/overview`,
  WALLET: `${DASHBOARD_PATH}/wallet`,
  CHARGE_TAGS: `${DASHBOARD_PATH}/charge-tags`,
  FAQS: 'https://www.openloop.co.nz/faqs',
  ABOUT_US: 'https://www.openloop.co.nz/#aboutus',
  TRANSACTIONS: `${DASHBOARD_PATH}/transactions`,
  TRANSACTION: `${DASHBOARD_PATH}/transactions/:id`,
  PROFILE: `${DASHBOARD_PATH}/profile`,
};

export default paths;
