import _ from 'lodash';

const isEmptyString = (s) => !s || s.length === 0;

const isBlankString = (s) => !s || /^\s*$/.test(s);

const includesSubstringIgnoreCase = (s, substring) => {
  if (isEmptyString(s)) {
    throw Error('String cannot be empty');
  }

  if (isEmptyString(substring)) {
    throw Error('Substring cannot be empty');
  }

  return s.toLowerCase().includes(substring.toLowerCase());
};

const isEqualIgnoreCase = (value, other) => {
  if (isEmptyString(value)) {
    throw Error('String cannot be empty');
  }

  if (isEmptyString(other)) {
    throw Error('Compared string cannot be empty');
  }

  return _.isEqual(value.toLowerCase(), other.toLowerCase());
}

const lodashExtras = {
  includesSubstringIgnoreCase,
  isEmptyString,
  isBlankString,
  isEqualIgnoreCase,
};

export default lodashExtras;
